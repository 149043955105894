type AcceptStatementProps = {
  testId: string;
};

export const AcceptStatement = ({ testId }: AcceptStatementProps) => (
  <div className="mt-8 md:mt-12">
    <p className="font-lato text-sm" data-testid={`${testId}_accept-statement_text`}>
      By continuing, I understand this sale is non-advised. I confirm the above
      information is correct, I am responsible for ensuring the policy meets my demands
      and needs and that I have read the{' '}
      <a
        target="_blank"
        rel="noreferrer"
        className="font-bold underline link"
        href="https://didevwebresources.blob.core.windows.net/resources/DocumentStore/Annual/Everydayinsure/TOBA/EDI%20TOBA%20v1.0.pdf"
        data-testid={`${testId}_accept-statement_refound-policy-link`}
      >
        Terms of Business
      </a>
      .
    </p>
  </div>
);
