export enum QuoteJourneyRoutes {
  RegistrationSearch = 'registration-search',
  CarDetails = 'car-details',
  CarUsage = 'car-usage',
  YourDetails = 'your-details',
  YourHome = 'your-home',
  YourLicenceAndHistory = 'your-licence-and-history',
  Drivers = 'drivers',
  AdditionalDriverId = ':driverId',
  DriverDetails = 'details',
  DriverLicenceAndHistory = 'driver-licence-and-history',
  Cover = 'cover',
  QuoteId = ':quoteId',
  CarSecurity = 'car-security',
  CarSecurityPolicyConditions = 'car-security-policy-conditions',
  YourQuote = 'your-quote',
  Review = 'review',
  Payment = 'payment',
  PurchaseComplete = 'purchase-complete',
  PolicyConditions = 'policy-conditions',
  Empty = 'empty',
  Pcw = 'pcw',
}

export enum PoliciesPaths {
  Policies = 'policies',
  PolicyId = ':id',
  CarGroup = 'car/*',
  Car = 'car',
  CarInfo = 'info',
  CarDetails = 'details',
  CarModifications = 'modifications',
  CarPurchaseDate = 'purchase-date',
  CarUsage = 'usage',
  CancelGroup = 'cancel/*',
  Cancel = 'cancel',
  CancelConfirmed = 'confirmed',
  DriversGroup = 'drivers/*',
  Drivers = 'drivers',
  DriverId = ':driverId',
  DriverDetails = 'details',
  DriverHome = 'home',
  DriverLicenceAndHistory = 'licence-and-history',
  PaymentsGroup = 'payments/*',
  Payments = 'payments',
  PaymentsDirectDebit = 'direct-debit',
  PaymentsAddCard = 'add-card',
  Claims = 'claims',
  Renewal = 'renewal',
  UpcomingChanges = 'upcoming-changes',
}

export enum PolicyMtaPaths {
  Mta = 'mta/*',
  PolicyChange = 'policy-change',
  NewCar = 'new-car',
  NewCarUsage = 'new-car-usage',
  NewCost = 'new-cost',
  NewReg = 'new-reg',
  DriversGroup = 'drivers/*',
  Drivers = 'drivers',
  DriverId = ':driverId',
  DriverDetails = 'details',
  DriverLicenceAndHistory = 'licence-and-history',
  Review = 'review',
  QuoteId = ':quoteId',
  Payment = 'payment',
  ChangeComplete = 'change-complete',
  CarSecurity = 'car-security',
  CarSecurityPolicyConditions = 'car-security-policy-conditions',
  PolicyConditions = 'policy-conditions',
  Empty = 'empty',
}

export enum AccountPaths {
  Details = 'details',
  PasswordEdit = 'password-edit',
  PaymentMethodsGroup = 'payment-methods/*',
  PaymentMethods = 'payment-methods',
  SavedCards = 'saved-cards',
  PaymentsDirectDebit = 'direct-debit',
  PaymentsAddCard = 'add-card',
}

export enum AuthPaths {
  ForgottenPasswordGroup = 'forgotten-password/*',
  ForgottenPassword = 'forgotten-password',
  PasswordSecureLink = 'secure-link',
  PasswordSecureLinkExired = 'secure-link-expired',
  PasswordReset = 'reset',
  PasswordSuccess = 'success',
  SignUpGroup = 'sign-up/*',
  SignUp = 'sign-up',
  LinkSent = 'link-sent',
  VerifyGroup = 'verify/*',
  Verify = 'verify',
  VerifySuccess = 'success',
  VerifyFailure = 'failure',
  SessionExpired = 'session-expired',
  Logout = 'logout',
}
