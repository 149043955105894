import { AddOnModelDto, MotorPolicyAddOnModelDto } from '../../../api/v1';
import { AddOnType } from '../../../pages/Quote/YourQuote/Addons/AddOns.types';

const RACAddOnCodes = [
  AddOnType.BREAKDOWN_1,
  AddOnType.BREAKDOWN_2,
  AddOnType.BREAKDOWN_3,
  AddOnType.BREAKDOWN_4,
];

const isAddOnModelDto = (
  addOn: MotorPolicyAddOnModelDto | AddOnModelDto
): addOn is AddOnModelDto => 'selected' in addOn;

export const hasRACAddOn = (
  addOns?: MotorPolicyAddOnModelDto[] | AddOnModelDto[] | null
): boolean => {
  if (!addOns) {
    return false;
  }

  return addOns.some(addOn => {
    if (isAddOnModelDto(addOn)) {
      return RACAddOnCodes.includes(addOn.type?.code as AddOnType) && addOn.selected;
    }

    return RACAddOnCodes.includes(addOn.type?.code as AddOnType);
  });
};
