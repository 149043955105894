import '@adyen/adyen-web/dist/adyen.css';
import { LoadingSpinner, PaymentDateField, PaymentField } from '@dayinsure/components';
import { useFormikContext } from 'formik';
import clsx from 'clsx';
import { useContext, useEffect, useRef } from 'react';
import { FormTextField, FormFieldBox } from '../../../../components';
import { nameInputMask } from '../../../../helpers';
import {
  UNSUPPORTED_CARD_BRAND_ERROR_MESSAGE,
  useFocusOnError,
  usePaymentCheckout,
} from '../../../../hooks';
import { QuoteJourneyFormData } from '../../../../types';
import { PaymentContext } from '../../../../contexts/PaymentContext';

type PayByDebitCarProps = {
  mta?: boolean;
  noTitle?: boolean;
};

const testId = 'pay-by-debit-card';
export const PayByDebitCard = ({ mta, noTitle }: PayByDebitCarProps) => {
  const encryptedCardNumberBoxRef = useRef<HTMLDivElement>(null);
  const paymentDatefieldsBoxRef = useRef<HTMLDivElement>(null);
  const encryptedSecurityCodeBoxRef = useRef<HTMLDivElement>(null);

  const {
    customCard,
    paymentFocusStates,
    paymentErrorStates,
    isConfigSuccess,
    setCheckoutContext,
  } = usePaymentCheckout(mta);
  const { errors, touched, handleBlur, setFieldValue } =
    useFormikContext<QuoteJourneyFormData>();
  const { cardFormVisible } = useContext(PaymentContext);

  useEffect(() => {
    setFieldValue(
      'isUnsupportedCardBrand',
      paymentErrorStates.encryptedCardNumber === UNSUPPORTED_CARD_BRAND_ERROR_MESSAGE
    );
  }, [paymentErrorStates.encryptedCardNumber, setFieldValue]);

  useFocusOnError({
    fieldRef: encryptedCardNumberBoxRef,
    name: 'encryptedCardNumber',
  });
  useFocusOnError({
    fieldRef: paymentDatefieldsBoxRef,
    name: 'encryptedExpiryMonth',
  });
  useFocusOnError({
    fieldRef: paymentDatefieldsBoxRef,
    name: 'encryptedExpiryYear',
  });
  useFocusOnError({
    fieldRef: encryptedSecurityCodeBoxRef,
    name: 'encryptedSecurityCode',
  });
  useEffect(() => {
    return () => setCheckoutContext(null);
  }, [setCheckoutContext]);

  return (
    <>
      <div id="my-container" />
      <LoadingSpinner
        centered
        className={clsx('my-8 relative,', {
          hidden: isConfigSuccess,
        })}
      />
      <div
        id="customCard-container"
        className={clsx({ hidden: !cardFormVisible || !isConfigSuccess })}
      >
        {!noTitle && (
          <h3
            className="relative w-full font-raleway font-normal form-subtitle-compact"
            data-testid={`${testId}_payment-form-title`}
          >
            Debit / credit card
          </h3>
        )}
        <FormFieldBox ref={encryptedCardNumberBoxRef}>
          <PaymentField
            label={{
              icon: 'card',
              text: 'Card number',
            }}
            iframeId="encryptedCardNumber"
            focused={paymentFocusStates.encryptedCardNumber}
            error={
              (touched.encryptedCardNumber && paymentErrorStates.encryptedCardNumber) ||
              (touched.encryptedCardNumber && errors.encryptedCardNumber) ||
              undefined
            }
          />
        </FormFieldBox>
        <FormFieldBox>
          <FormTextField
            placeholder="e.g. MR J SMITH"
            name="cardholderName"
            id={`${testId}_card-name-input`}
            testId={`${testId}_card-name-input`}
            label={{ text: 'Name on card', icon: 'user' }}
            onInput={nameInputMask}
            onBlur={handleBlur}
          />
        </FormFieldBox>
        <FormFieldBox ref={paymentDatefieldsBoxRef}>
          <PaymentDateField
            securedFields={customCard}
            label={{
              icon: 'date',
              text: 'Expiry date',
            }}
            iframeIdMonth="encryptedExpiryMonth"
            iframeIdYear="encryptedExpiryYear"
            focused={
              paymentFocusStates.encryptedExpiryMonth ||
              paymentFocusStates.encryptedExpiryYear
            }
            error={
              (touched.encryptedExpiryMonth && paymentErrorStates.encryptedExpiryMonth) ||
              (touched.encryptedExpiryMonth && errors.encryptedExpiryMonth) ||
              (touched.encryptedExpiryYear && paymentErrorStates.encryptedExpiryYear) ||
              (touched.encryptedExpiryYear && errors.encryptedExpiryYear) ||
              undefined
            }
          />
        </FormFieldBox>
        <FormFieldBox ref={encryptedSecurityCodeBoxRef}>
          <div className="relative w-full sm:w-56 lg:w-80">
            <PaymentField
              label={{
                icon: 'lock',
                text: 'Security code',
              }}
              iframeId="encryptedSecurityCode"
              focused={paymentFocusStates.encryptedSecurityCode}
              error={
                (touched.encryptedSecurityCode &&
                  paymentErrorStates.encryptedSecurityCode) ||
                (touched.encryptedSecurityCode && errors.encryptedSecurityCode) ||
                undefined
              }
            />
          </div>
        </FormFieldBox>
      </div>
      <div id="3ds-container" />
    </>
  );
};
