import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { QuoteJourneyFormData } from '../../../../types';
import { useConfirmAccountExistQuery, usePerson } from '../../../../hooks';
import { CreateAccountForm } from './CreateAccountForm';
import { UserEmailCard } from './CreateAccountForm/UserEmailCard';
import { Loader } from '../../../../components';

type CreateAccountProps = {
  testId: string;
};

export const CreateAccount = ({ testId }: CreateAccountProps) => {
  const { isFullAccount } = usePerson();
  const {
    setFieldValue,
    values: {
      isAccountCreated,
      proposer: { email },
      wasEmailChangedAfterCreation,
    },
  } = useFormikContext<QuoteJourneyFormData>();
  const { data, isLoading } = useConfirmAccountExistQuery(email, !isFullAccount);

  useEffect(() => {
    if (isFullAccount || data?.found) {
      setFieldValue('isCreateAccountDisplayed', false);
    } else {
      setFieldValue('isCreateAccountDisplayed', true);
    }
  }, [isFullAccount, setFieldValue, data]);

  if (isFullAccount) {
    return null;
  }

  if (isLoading) {
    return <Loader />;
  }

  const shouldDisplayAccountFrom =
    ((!isFullAccount && !isAccountCreated) || wasEmailChangedAfterCreation) &&
    !data?.found;

  return (
    <div className="my-6 md:mt-12" data-testid={`${testId}_create-account`}>
      {shouldDisplayAccountFrom && (
        <h3
          className="relative w-full font-raleway font-normal form-subtitle-compact"
          data-testid={`${testId}_create-account_title`}
        >
          Create an account
        </h3>
      )}
      <UserEmailCard className="mb-8" email={email} testId={testId} />
      {shouldDisplayAccountFrom && <CreateAccountForm testId={testId} />}
    </div>
  );
};
