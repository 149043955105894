import { Card, Icon } from '@dayinsure/components';
import clsx from 'clsx';
import { formatInUKLocalTime } from '@dayinsure/shared';

type CancellationPendingPanelProps = {
  cancelledDateTimeUtc?: string | null;
  testId?: string;
};
export const CancellationPendingPanel = ({
  testId,
  cancelledDateTimeUtc,
}: CancellationPendingPanelProps) => {
  const changesDay = formatInUKLocalTime('dd/MM/yyyy', cancelledDateTimeUtc);
  const changesTime = formatInUKLocalTime('HH:mm', cancelledDateTimeUtc);
  return (
    <Card
      classNames={clsx('flex items-center', 'mb-8', 'mt-8 lg:mt-12')}
      paddingLevel="small"
      testId={`${testId}_cancellation-pending-panel_card`}
    >
      <Icon className="text-main-content-1" size="2rem" name="info" />
      <div className="ml-6 text-main-content-1">
        This policy is due to be cancelled on {changesDay} at {changesTime}. If you need
        more help, please contact us through live chat.
      </div>
    </Card>
  );
};
