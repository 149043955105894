import { Occupation, QuoteJourneyFormData } from '../../../types';
import { Address, PersonRequest, PersonType } from '../../../api/v2';
import { UpdatePersonRequest, ConvertToCustomerRequest } from '../../../api/v1';
import { AddressDto as AddressDtoV3 } from '../../../api/v3';
import Referrer from '../../../types/referrer';

const driverAddressToPersonRequestAddress = (
  address: AddressDtoV3 | null,
  postcode: string
): Address => {
  return {
    country: address?.country,
    thoroughfare: address?.thoroughfare,
    town: address?.town,
    postcode: address?.postcode || postcode,
    county: address?.county,
    organisation: address?.organisationName,
    buildingName: address?.buildingName,
    buildingNumber: address?.buildingNumber,
    subBuildingName: address?.subBuildingName,
  };
};

const driverAddressToPersonUpdateRequestAddress = (
  address: AddressDtoV3 | null
): Partial<UpdatePersonRequest> => {
  return {
    addressCountry: address?.country,
    thoroughfare: address?.thoroughfare,
    town: address?.town,
    addressPostcode: address?.postcode,
    county: address?.county,
    organisation: address?.organisationName,
    buildingName: address?.buildingName,
    buildingNumber: address?.buildingNumber,
    subBuildingName: address?.subBuildingName,
  };
};

const driverOccupationToPersonRequestOccupation = (
  occupations: Occupation[]
): PersonRequest['occupation'][] =>
  occupations.map(occupation => ({
    id: occupation.type?.id,
    description: occupation.type?.name,
  }));

export const getConvertGuestToCustomerPayload = (
  { signUp }: QuoteJourneyFormData,
  referrer: Referrer
): ConvertToCustomerRequest => {
  return {
    password: signUp.password,
    referrer: referrer.name,
    confirmationUrl: `${window.origin}/auth/sign-up/verify`,
  };
};

export const getCreateGuestAccountRequestPayload = (
  { proposer, drivers }: QuoteJourneyFormData,
  referrer: Referrer
): PersonRequest => {
  return {
    type: PersonType.GUEST,
    identityProvider: referrer.identityProviderName,
    referrer: referrer.name,
    email: proposer.email,
    firstName: proposer.firstName,
    lastName: proposer.lastName,
    mainPhoneNumber: proposer.telephoneNumber.mobile || proposer.telephoneNumber.landline,
    secondaryPhoneNumber: proposer.telephoneNumber.landline,
    dateOfBirth: proposer.dateOfBirth?.parsedDate,
    drivingLicenceNumber: drivers[0].drivingLicence.number,
    occupation: driverOccupationToPersonRequestOccupation(drivers[0]?.occupations)[0],
    address: driverAddressToPersonRequestAddress(
      drivers[0].address,
      drivers[0].homePostcode || ''
    ),
  };
};

export const getUpdatePersonRequestPayload = ({
  proposer,
  drivers,
}: QuoteJourneyFormData): UpdatePersonRequest => {
  return {
    firstName: proposer.firstName,
    lastName: proposer.lastName,
    dateOfBirth: proposer.dateOfBirth?.parsedDate,
    mainPhoneNumber: proposer.telephoneNumber.mobile || proposer.telephoneNumber.landline,
    secondaryPhoneNumber: proposer.telephoneNumber.landline,
    occupationId: drivers[0].occupations[0].type?.id,
    occupationDescription: drivers[0].occupations[0].type?.name,
    drivingLicenceNumber: drivers[0].drivingLicence.number,
    licenceType: drivers[0].drivingLicence.type?.id,
    email: proposer.email,
    ...driverAddressToPersonUpdateRequestAddress(drivers[0].address),
  };
};
