import { Fragment } from 'react';
import { Card } from '@dayinsure/components';
import { format } from 'date-fns';
import { Loader } from '../../../../../../components';
import { DriverCardsProps } from '../DriverCards.types';
import { displayJobTitleBaseOnEmploymentStatus } from '../../../../../../helpers';

export const DriverDetailsCard = ({ driver, testId }: DriverCardsProps) => {
  if (!driver) {
    return <Loader />;
  }

  const primaryOccupation = driver.occupations?.find(occupation => occupation.isPrimary);
  const displayOccupation = displayJobTitleBaseOnEmploymentStatus(
    primaryOccupation?.employmentStatus?.code
  );

  const title = driver?.isPolicyholder
    ? 'Policyholder’s details'
    : 'Additional driver’s details';

  return (
    <Card testId={`${testId}_drivers-details-card`} classNames="w-full mb-6">
      <h3 className="font-raleway text-lg font-normal">{title}</h3>
      {!driver?.isPolicyholder && (
        <Card.CardListItem
          label="Relationship to you"
          icon="chain"
          testId={`${testId}_drivers-details-card_list-item_relation`}
        >
          {driver.relationshipToPolicyholder?.description ||
            driver.relationshipToPolicyholder?.code}
        </Card.CardListItem>
      )}
      <Card.CardListItem
        label="Name"
        icon="user"
        testId={`${testId}_drivers-details-card_list-item_name`}
      >
        {`${driver.firstName} ${driver.lastName}`}
      </Card.CardListItem>
      <Card.CardListItem
        label="Date of birth"
        icon="date"
        testId={`${testId}_drivers-details-card_list-item_dob`}
      >
        {driver.dateOfBirth
          ? format(new Date(driver.dateOfBirth), 'dd/MM/yyyy')
          : undefined}
      </Card.CardListItem>
      <Card.CardListItem
        label="Marital status"
        icon="heart"
        testId={`${testId}_drivers-details-card_list-item_martial-status`}
      >
        {driver.maritalStatus?.description || driver.maritalStatus?.code}
      </Card.CardListItem>
      {driver.ukResidencyDetails?.ukResidentSince && (
        <Card.CardListItem
          label="Date moved to UK"
          icon="date"
          testId={`${testId}_drivers-details-card_list-item_date-move-to-uk`}
        >
          {format(new Date(driver.ukResidencyDetails.ukResidentSince), 'MM/yyyy')}
        </Card.CardListItem>
      )}
      <Card.CardListItem
        label="Employment status"
        icon="briefcase"
        testId={`${testId}_drivers-details-card_list-item_employment-status`}
      >
        {primaryOccupation?.employmentStatus?.description ||
          primaryOccupation?.employmentStatus?.code}
      </Card.CardListItem>
      {displayOccupation &&
        driver.occupations?.map(({ isPrimary, type, industry }) => (
          <Fragment key={`${type?.code}-${industry?.code}`}>
            <Card.CardListItem
              label={isPrimary ? 'Job title' : 'Other job title'}
              icon="clipboard-with-pen"
              testId={
                isPrimary
                  ? `${testId}_drivers-details-card_list-item_job-title`
                  : `${testId}_drivers-details-card_list-item_other-job-title`
              }
            >
              {type?.description || type?.code}
            </Card.CardListItem>
            <Card.CardListItem
              label={isPrimary ? 'Job industry' : 'Other job industry'}
              icon="industry"
              testId={
                isPrimary
                  ? `${testId}_drivers-details-card_list-item_job-industry`
                  : `${testId}_drivers-details-card_list-item_other-job-industry`
              }
            >
              {industry?.description || industry?.code}
            </Card.CardListItem>
          </Fragment>
        ))}
    </Card>
  );
};
