import { PageContainer, ContentContainer, Button } from '@dayinsure/components';
import { useNavigateWithReferrer } from '@dayinsure/shared';
import { useParams } from 'react-router-dom';
import { CarDetailsCard } from './CarDetailsCard';
import { ModificationsCard } from './ModificationsCard';
import { PurchaseDateCard } from './PurchaseDateCard';
import { CarUsageCard } from './CarUsageCard';
import { CarSpecsCard } from './CarSpecsCard';
import { usePolicyQuery } from '../../../../hooks';
import { CarValueCard } from './CarValueCard';
import { Loader } from '../../../../components';

const testId = 'car-details';

export const CarInfo = () => {
  const { id } = useParams<{ id: string }>();
  const { data } = usePolicyQuery(id);
  const navigate = useNavigateWithReferrer();

  if (!data || !data.vehicle) {
    return <Loader />;
  }

  const handleNavigate = () => {
    navigate(`/policies/${id}`);
  };

  return (
    <PageContainer testId={testId}>
      <ContentContainer>
        <div className="flex flex-col items-center mt-8 mb-16 w-full sm:mt-12 md:mb-24 text-main-content-1">
          <h1
            className="self-center mb-8 text-xl sm:mb-12"
            data-testid={`${testId}_title`}
          >
            Your car
          </h1>
          <CarDetailsCard testId={testId} vehicle={data.vehicle} />
          <CarSpecsCard testId={testId} vehicle={data.vehicle} />
          {data.vehicle.selfDeclaredVehicleValue?.amount && (
            <CarValueCard
              testId={testId}
              value={data.vehicle.selfDeclaredVehicleValue.amount}
            />
          )}
          {data.vehicle.modifications?.length !== 0 && (
            <ModificationsCard
              testId={testId}
              vehicleModifications={data.vehicle.modifications}
            />
          )}
          {data.vehicle.ownership?.selfDeclaredDateOfPurchase && (
            <PurchaseDateCard
              testId={testId}
              purchaseDate={data.vehicle.ownership?.selfDeclaredDateOfPurchase}
            />
          )}
          <CarUsageCard testId={testId} vehicle={data.vehicle} mta />
          <Button
            variant="secondary"
            classNames="w-full mt-6 md:mt-10"
            id={`${testId}_back-to-policy-button`}
            data-testid={`${testId}_back-to-policy-button`}
            text="Back to policy"
            onClick={handleNavigate}
          />
        </div>
      </ContentContainer>
    </PageContainer>
  );
};
