export const query = {
  confirmAccountExist: (email: string) => ['account-exist', email],
  addressDetail: (postKey: string) => ['address-detail', postKey],
  addressLookup: (postcode: string) => ['address-lookup', postcode],
  claimTypes: ['claim-types'],
  convictionTypes: ['conviction-types'],
  coverOptions: ['cover-options'],
  documents: ['documents'],
  driverOptions: ['driver-options'],
  drivingQualifications: ['driving-qualifications'],
  employmentIndustries: (employmentIndustriesQuery: string) => [
    'employment-industries',
    employmentIndustriesQuery,
  ],
  employmentStatuses: ['employment-statuses'],
  studentOccupations: ['student-occupations'],
  licenceDurationsOptions: ['licence-durations-options'],
  marketingPreferences: (personId: string) => ['marketing-preferences', personId],
  marketingConsent: ['marketing-consent'],
  medicalConditionsOptions: ['medical-conditions-options'],
  occupations: (occupationQuery: string) => ['occupations', occupationQuery],
  person: (personId: string) => ['person', personId],
  policy: (policyId: string, version: number | undefined) => [
    'policy',
    policyId,
    version,
  ],
  policyDocuments: (policyId: string) => ['policy-documents', policyId],
  policySummary: (personId: string) => ['policy-summary', personId],
  motorPolicySummary: (state?: string[]) => ['policy-summary', state],
  motorPoliciesSummary: ['motor-policies'],
  quoteCreate: (quoteId: string) => ['quote-create', quoteId],
  quoteDetails: (quoteId: string) => ['quote-details', quoteId],
  vehicleModifications: (searchTerm: string) => ['vehicle-modifications', searchTerm],
  vehicleOptions: ['vehicle-options'],
  vehicleOwner: ['vehicle-owner'],
  vehicleOtherUsage: ['vehicle-other-usage'],
  vehicleReg: (registrationNumber: string) => ['vehicle-reg', registrationNumber],
  makeOrder: (quoteId: string) => ['quote-create', quoteId],
  endorsements: ['endorsements'],
  immobilisers: ['immobilisers'],
  resumeOrder: (id: string) => ['quote-create', id],
  order: (orderId: string) => ['order', orderId],
  mtaQuote: (id: string) => ['quote-mta-create', id],
  paymentMethods: 'payment-methods',
  productSchemaCover: (type: string) => ['product-schema-cover', type],
  addOnsSchema: ['addons-schema'],
};
