import { Link } from '../../../../components';

type TermsAndConditionsProps = {
  testId: string;
};

export const TermsAndConditions = ({ testId }: TermsAndConditionsProps) => (
  <section
    className="mb-12 font-lato text-sm md:mb-16"
    data-testid={`${testId}_terms-and-conditions`}
  >
    <p className="mb-6">
      Before continuing it is important to carefully read the Everydayinsure{' '}
      <Link href="https://didevwebresources.blob.core.windows.net/resources/DocumentStore/Annual/Everydayinsure/TOBA/EDI%20TOBA%20v1.0.pdf">
        Terms of Business
      </Link>
      , the{' '}
      <Link href="https://didevwebresources.blob.core.windows.net/resources/DocumentStore/Annual/Everydayinsure/PrivacyPolicy/EDI%20Privacy%20Policy%20v1.0.pdf">
        Privacy Policy
      </Link>
      , the{' '}
      <Link href="https://didevwebresources.blob.core.windows.net/resources/DocumentStore/Annual/Everydayinsure/IPID/EDI%20IPID%20v1.0.pdf">
        Motor Insurance Product Information
      </Link>{' '}
      documents relating to your policy. Also please check the above answers are correct,
      as incorrect information may affect your policy, the price you pay for it and / or a
      claim may be declined. You will only be able to amend the policy by contacting
      customer services. If you amend the policy after you have accepted it we may charge
      you an administrative fee to do so.
    </p>
  </section>
);
