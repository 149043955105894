/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateMtaQuoteDto } from '../models/CreateMtaQuoteDto';
import type { MotorPolicyDocumentDto } from '../models/MotorPolicyDocumentDto';
import type { MotorPolicyDto } from '../models/MotorPolicyDto';
import type { MotorPolicySummaryDto } from '../models/MotorPolicySummaryDto';
import type { MtaQuoteDto } from '../models/MtaQuoteDto';
import type { PatchPolicyRequestDto } from '../models/PatchPolicyRequestDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MotorPolicyService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Gets a motor policy for a given id
   * @param id
   * @param policyVersion
   * @returns MotorPolicyDto Success
   * @throws ApiError
   */
  public getApiV1MotorPolicy(
    id: string,
    policyVersion?: number,
  ): CancelablePromise<MotorPolicyDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/motor-policy/{id}',
      path: {
        'id': id,
      },
      query: {
        'policyVersion': policyVersion,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Patch Policy
   * @param id
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public patchApiV1MotorPolicy(
    id: string,
    requestBody?: PatchPolicyRequestDto,
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/motor-policy/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        422: `Client Error`,
      },
    });
  }

  /**
   * Gets a list of motor policies for the signed in user
   * @param state A filter to include only the polcies in the given state
   * @param motorQuoteId
   * @returns MotorPolicySummaryDto Success
   * @throws ApiError
   */
  public getApiV1MotorPolicy1(
    state?: Array<string>,
    motorQuoteId?: string,
  ): CancelablePromise<Array<MotorPolicySummaryDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/motor-policy',
      query: {
        'state': state,
        'motorQuoteId': motorQuoteId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
        422: `Client Error`,
      },
    });
  }

  /**
   * Gets a list of motor policies for the signed in user
   * @param id
   * @returns MotorPolicyDocumentDto Success
   * @throws ApiError
   */
  public getApiV1MotorPolicyDocuments(
    id: string,
  ): CancelablePromise<Array<MotorPolicyDocumentDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/motor-policy/{id}/documents',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Creates an Mta quote for a policy
   * @param id
   * @param requestBody
   * @returns MtaQuoteDto Success
   * @throws ApiError
   */
  public postApiV1MotorPolicyMtaQuote(
    id: string,
    requestBody?: CreateMtaQuoteDto,
  ): CancelablePromise<MtaQuoteDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/motor-policy/{id}/mta-quote',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
        422: `Client Error`,
      },
    });
  }

}