import { ReactNode } from 'react';
import {
  useQuoteNavigation,
  useResetPolicyConditionConfirmation,
} from '../../../../hooks';

type QuoteJourneySideEffectsProps = {
  children: ReactNode;
};

export const QuoteJourneySideEffects = ({ children }: QuoteJourneySideEffectsProps) => {
  useQuoteNavigation();
  useResetPolicyConditionConfirmation();
  return <>{children}</>;
};
