import { Card, Icon } from '@dayinsure/components';
import { formatInUKLocalTime } from '@dayinsure/shared';
import { DriverFormData } from '../../../../../types';
import { isNo, isYes } from '../../../../../helpers';

type DriverListItemProps = {
  driver: DriverFormData;
  testId: string;
  onEditDriver(): void;
  onRemoveDriver(): void;
};

export const DriverListItem = ({
  driver: { firstName, lastName, dateOfBirth, drivingLicence, isProposer },
  testId,
  onEditDriver,
  onRemoveDriver,
}: DriverListItemProps) => {
  const name = `${firstName} ${lastName}`;
  const birthdate = dateOfBirth?.parsedDate
    ? formatInUKLocalTime('dd/MM/yyyy', dateOfBirth?.parsedDate)
    : undefined;

  const title = isYes(isProposer?.id) ? 'Policyholder' : 'Additional driver';

  return (
    <Card classNames="mb-8" testId={`${testId}_list-item`}>
      <div className="flex justify-between">
        <h3 className="font-raleway text-lg font-normal">{title}</h3>
        <div className="flex flex-col items-end md:flex-row">
          {isNo(isProposer?.id) && (
            <button
              className="flex items-center mr-0 mb-2 text-sm font-semibold leading-[14px] md:mr-4 md:mb-0 text-link"
              data-testid={`${testId}_remove-driver-button`}
              onClick={onRemoveDriver}
              type="button"
            >
              <Icon name="remove" className="mr-2" size="12px" />
              Remove
            </button>
          )}
          <button
            className="flex items-center text-sm font-semibold leading-[14px] text-link"
            data-testid={`${testId}_edit-driver-button`}
            onClick={onEditDriver}
            type="button"
          >
            <Icon name="edit" className="mr-2" size="12px" />
            Edit
          </button>
        </div>
      </div>
      <Card.CardListItem label="Name" icon="user" testId={`${testId}_list-item_name`}>
        {name}
      </Card.CardListItem>
      <Card.CardListItem
        label="Date of birth"
        icon="date"
        testId={`${testId}_list-item_birthday`}
      >
        {birthdate}
      </Card.CardListItem>
      <Card.CardListItem
        label="Licence type"
        icon="card"
        testId={`${testId}_list-item_licence-type`}
      >
        {drivingLicence?.type?.name}
      </Card.CardListItem>
    </Card>
  );
};
