import { Icon } from '@dayinsure/components';

type KeysProps = {
  testId: string;
};

export const Keys = ({ testId }: KeysProps) => {
  return (
    <div className="font-lato text-sm leading-5" data-testid={testId}>
      <h4>
        Your policy includes up to £500 cover for stolen keys. Would you need extra cover
        if your keys were lost, damaged or stolen?
      </h4>

      <ul className="pl-4 my-4 list-disc">
        <li>Up to £1,500 cover for replacement keys and locks</li>
        <li>Onward travel costs covered up to £100</li>
        <li>Cover provided by Keycare</li>
      </ul>

      <div>
        <div className="flex items-center mt-6">
          <Icon name="download" className="mr-2 text-link" />
          <a
            className="font-raleway text-base font-semibold underline text-link"
            href="/#"
            target="_blank"
            rel="noopener noreferrer"
          >
            Insurance product information document
          </a>
        </div>
        <div className="flex items-center mt-6">
          <Icon name="download" className="mr-2 text-link" />
          <a
            className="font-raleway text-base font-semibold underline text-link"
            href="/#"
            target="_blank"
            rel="noopener noreferrer"
          >
            Policy Wording
          </a>
        </div>
      </div>
    </div>
  );
};
