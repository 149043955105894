import { CostDto, ExcessesResponseDto } from '../../api/v1';
import {
  getExcessValueNumber,
  getVoluntaryExcessesValueNumber,
} from '../forms/getExcessesValue/getExcessesValue';
import { EXCESSES } from '../../constants/payments';

export const carValueIsLessOrEqualThanExcesses = (
  excesses?: ExcessesResponseDto,
  carValue?: CostDto
): boolean => {
  if (!excesses || !carValue?.amount) {
    return false;
  }
  const { compulsoryAmounts, voluntaryAmounts } = excesses;
  const compulsoryExcessesValue = getExcessValueNumber(
    compulsoryAmounts,
    EXCESSES.policy
  );
  const voluntaryExcessesValue = getVoluntaryExcessesValueNumber(voluntaryAmounts || []);

  const sum = compulsoryExcessesValue + voluntaryExcessesValue;
  return sum >= carValue.amount;
};
