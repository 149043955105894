import { Card, Icon } from '@dayinsure/components';
import clsx from 'clsx';

type CardWithProcessingPaymentProps = {
  testId: string;
};
export const CardWithProcessingPayment = ({ testId }: CardWithProcessingPaymentProps) => {
  return (
    <section>
      <Card
        classNames={clsx('flex items-center', 'mb-8', 'mt-8 lg:mt-12')}
        paddingLevel="small"
        testId={`${testId}_card`}
      >
        <Icon className="text-main-content-1" size="2rem" name="warning" />
        <div className="ml-6 text-main-content-1">
          Your policy has been created and we have processed your payment. We are
          generating your documents and will email them shortly. If you need more help,
          please contact us through live chat.
        </div>
      </Card>
    </section>
  );
};
