import { Card } from '@dayinsure/components';
import React from 'react';
import { AddOnModelDto, CoverDto, NoClaimsHistoryDto } from '../../../../api/v1';
import { fixYearsDispley, getClaimsBonusDescription } from '../../../../helpers';
import { PolicyInfoAutoRenew } from '../PolicyInfoAutoRenew';

type PolicyInfoCoversProps = {
  testId?: string;
  cover?: CoverDto;
  addons?: AddOnModelDto[] | null;
  noClaimsHistory?: NoClaimsHistoryDto | null;
  isAutoRenewalRequested?: boolean;
  refetch?: () => void;
  isRefetching?: boolean;
  upcomingChanges?: boolean;
};

export const PolicyInfoCovers = ({
  testId,
  cover,
  addons,
  noClaimsHistory,
  isAutoRenewalRequested,
  refetch,
  isRefetching,
  upcomingChanges,
}: PolicyInfoCoversProps) => {
  const displayAddOns = addons?.length !== 0;
  const noClaimBonusPronun = getClaimsBonusDescription(
    noClaimsHistory?.yearsNoClaimsBonus
  );
  return (
    <section>
      <Card
        paddingLevel="small"
        classNames="bg-content-background-prominent mb-6"
        testId={`${testId}_cover`}
      >
        <h2 className="mb-4 font-raleway text-lg">Cover</h2>
        <Card.CardListItem
          label="Cover type"
          icon="dpolicies"
          testId={`${testId}_cover-type_list-item`}
        >
          {cover?.type?.description}
        </Card.CardListItem>

        {displayAddOns && (
          <Card.CardListItem
            label="Add-ons"
            icon="addons"
            testId={`${testId}_cover_addons_list-item`}
          >
            {addons?.map((addon, index) => (
              <div key={`${testId}_addon-${index.toString()}`}>
                • {addon.type?.description || addon.type?.code}
              </div>
            ))}
          </Card.CardListItem>
        )}
        {typeof noClaimsHistory?.yearsNoClaimsBonus === 'number' && (
          <Card.CardListItem
            label="Number of years No Claims Bonus"
            icon="date"
            testId={`${testId}_no-claims-discount-years_list_item`}
          >
            {`${fixYearsDispley(
              noClaimsHistory?.yearsNoClaimsBonus
            )} ${noClaimBonusPronun}`}
          </Card.CardListItem>
        )}
        {typeof isAutoRenewalRequested === 'boolean' && !upcomingChanges && (
          <Card.CardListItem
            label="Auto-renew policy"
            icon="cycle"
            testId={`${testId}_auto-renew-all-policies`}
          >
            {isAutoRenewalRequested ? 'On' : 'Off'}
          </Card.CardListItem>
        )}
        {!upcomingChanges && (
          <PolicyInfoAutoRenew
            isAutoRenewalRequested={isAutoRenewalRequested}
            refetch={refetch}
            testId={testId}
            isRefetching={isRefetching}
          />
        )}
      </Card>
    </section>
  );
};
