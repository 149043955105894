import { useMutation } from 'react-query';
import { Dayinsure as DayInsureAPIv2, OpenAPI } from '../../../api/v1';

export const useValidateOtpMutation = () => {
  const validateOpt = (otp?: string) => {
    const api = new DayInsureAPIv2(OpenAPI);

    return api.pcwDeeplink.postApiV1PcwDeeplinkValidateOtp({ otp });
  };

  return useMutation({ mutationFn: validateOpt, mutationKey: 'validate-opt' });
};
