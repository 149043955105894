import { Icon, onEnter } from '@dayinsure/components';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GlobalErrorContext } from '@dayinsure/shared';
import clsx from 'clsx';
import { AutoRenewPolicyModal } from '../../../Account/AccountDetails/AutoRenewPolicyModal';
import { usePatchMotorPolicy } from '../../../../hooks/mutations/usePatchMotorPolicy/usePatchMotorPolicy';

type PolicyInfoAutoRenewPropsType = {
  isAutoRenewalRequested?: boolean;
  testId?: string;
  refetch?: () => void;
  isRefetching?: boolean;
};
export const PolicyInfoAutoRenew = ({
  isAutoRenewalRequested,
  refetch,
  testId,
  isRefetching,
}: PolicyInfoAutoRenewPropsType) => {
  const [loading, setLoading] = useState(false);
  const loadingState = loading || isRefetching;
  const { setGlobalError } = useContext(GlobalErrorContext);
  const { id } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [autoRenewPolicy, setAutoRenewPolicy] = useState(isAutoRenewalRequested);
  const patchMotorPolicy = usePatchMotorPolicy();

  if (!id) {
    return null;
  }

  const mutateMotorPolicy = (renew: boolean) => {
    setLoading(true);
    patchMotorPolicy.mutate(
      { id, payload: { autoRenewPolicy: renew } },
      {
        onError: () => {
          setLoading(false);
          setGlobalError(null, {
            title: 'Something went wrong',
            message: `Something doesn’t look quite right, please go back and try again.`,
            cta: 'Go back',
            ctaId: 'payment-decline_cta',
            onClose: () => {
              setAutoRenewPolicy(!renew);
            },
          });
        },
        onSuccess: () => {
          if (refetch) {
            refetch();
            setLoading(false);
          }
        },
      }
    );
  };
  const closeModal = (turnOn?: boolean) => {
    if (turnOn) {
      setAutoRenewPolicy(true);
    } else {
      setAutoRenewPolicy(false);
      mutateMotorPolicy(false);
    }
    setOpenModal(false);
  };

  const onRenewChange = () => {
    if(!loadingState){
      if (autoRenewPolicy) {
        setOpenModal(true);
      } else {
        setAutoRenewPolicy(true);
        mutateMotorPolicy(true);
      }
    }
  };
  return (
    <>
      <span
        data-testid={`${testId}_auto-renew-cta`}
        role="button"
        tabIndex={0}
        className={clsx(
          'flex gap-2 items-center my-2 mt-6 text-sm font-bold leading-3 text-cta-secondary-text',
          { 'opacity-40': loadingState }
        )}
        onClick={onRenewChange}
        onKeyDown={onEnter(onRenewChange)}
      >
        <Icon name="edit" size="0.75rem" />
        {autoRenewPolicy ? 'Turn off auto-renew' : 'Turn on auto-renew'}
      </span>
      <AutoRenewPolicyModal
        onClose={closeModal}
        open={openModal}
        testId={`${testId}_auto-renew_modal`}
      />
    </>
  );
};
