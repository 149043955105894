import { ReferenceDescriptionDto } from '../../api/v1';

export const displayTrackingDeviceInfo = (
  trackingDevice?: ReferenceDescriptionDto
): string => {
  if (trackingDevice?.description) {
    return trackingDevice?.description;
  }

  if (trackingDevice?.code) {
    return 'Yes';
  }

  return 'No';
};
