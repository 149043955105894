import { Icon } from '@dayinsure/components';

type CourtesyCarEnhancedProps = {
  testId: string;
};

export const CourtesyCarEnhanced = ({ testId }: CourtesyCarEnhancedProps) => {
  return (
    <div className="font-lato text-sm leading-5" data-testid={testId}>
      <h4>
        Your policy includes a small hatchback courtesy car, subject to availability.
        Would you need a larger car if yours was written off, stolen or being repaired,
        subject a claim being made?
      </h4>

      <ul className="pl-4 my-4 list-disc">
        <li>
          Guaranteed courtesy car for up to 14 days if yours is stolen or written off
        </li>
        <li>
          Guaranteed courtesy car for up to 28 days when yours is being repaired, subject
          to a claim being made
        </li>
        <li>Get a car that’s a similar size to a Ford Kuga or Vauxhall Insignia</li>
      </ul>

      <div>
        <div className="flex items-center mt-6">
          <Icon name="download" className="mr-2 text-link" />
          <a
            className="font-raleway text-base font-semibold underline text-link"
            href="/#"
            target="_blank"
            rel="noopener noreferrer"
          >
            Insurance product information document
          </a>
        </div>
        <div className="flex items-center mt-6">
          <Icon name="download" className="mr-2 text-link" />
          <a
            className="font-raleway text-base font-semibold underline text-link"
            href="/#"
            target="_blank"
            rel="noopener noreferrer"
          >
            Policy Wording
          </a>
        </div>
      </div>
    </div>
  );
};
