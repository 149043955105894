import { formatInUKLocalTime, useNavigateWithReferrer } from '@dayinsure/shared';
import { Button, Card } from '@dayinsure/components';
import { format } from 'date-fns';
import { PoliciesPaths } from '../../../../routes';
import { PolicyDriverDto, PolicyHolderDto } from '../../../../api/v1';
import {
  getDriverAddress,
  getLicenceHeldDescription,
  getDriverDescription,
} from '../../../../helpers';

type PolicyInfoDriversProps = {
  testId?: string;
  drivers: Array<PolicyDriverDto>;
  policyHolder: PolicyHolderDto;
  noMoreButton?: boolean;
};

export const PolicyInfoDrivers = ({
  testId,
  drivers,
  policyHolder,
  noMoreButton,
}: PolicyInfoDriversProps) => {
  const navigate = useNavigateWithReferrer();
  const handleClick = () => {
    navigate(PoliciesPaths.Drivers);
  };

  return (
    <section>
      <Card
        paddingLevel="small"
        classNames="bg-content-background-prominent mb-6"
        testId={`${testId}_drivers`}
      >
        <h2 className="mb-4 font-raleway text-lg">Drivers</h2>
        {drivers.map((driver, index) => {
          const title = driver.isPolicyholder ? 'Policyholder' : 'Additional driver';
          const isLastItem = index === drivers.length - 1;
          return (
            <div key={`driver-${driver.dateOfBirth}`}>
              <Card.CardListItem
                label={title}
                icon="user"
                testId={`policy-driver_list-item_${index}_name`}
              >
                {getDriverDescription(driver)}
              </Card.CardListItem>
              {driver.ukResidencyDetails?.ukResidentSince && (
                <Card.CardListItem
                  label="Date moved to UK"
                  icon="date"
                  testId={`policy-driver_list-item_${index}_date-move-to-uk`}
                >
                  {format(new Date(driver.ukResidencyDetails.ukResidentSince), 'MM/yyyy')}
                </Card.CardListItem>
              )}
              {driver.isPolicyholder && (
                <Card.CardListItem
                  label="Policyholder’s home address"
                  icon="location"
                  testId={`policy-driver_list-item_${index}_home-address`}
                >
                  {getDriverAddress(policyHolder?.address?.formattedAddress)}
                </Card.CardListItem>
              )}
              <Card.CardListItem
                label="Licence type"
                icon="card"
                testId={`policy-driver_list-item_${index}_licence-type`}
              >
                {driver.drivingLicence?.type?.description ||
                  driver.drivingLicence?.type?.code}
              </Card.CardListItem>
              <Card.CardListItem
                label="Period licence held"
                icon="date"
                testId={`policy-driver_list-item_${index}_licence-held`}
              >
                {getLicenceHeldDescription(
                  driver.drivingLicence?.yearsHeld,
                  driver.drivingLicence?.monthsHeld
                )}
              </Card.CardListItem>

              <Card.CardListItem
                label="Claims"
                icon="clipboard"
                testId={`policy-driver_list-item_${index}_claims`}
              >
                {driver?.previousMotorClaims &&
                driver?.previousMotorClaims.length !== 0 ? (
                  <ul className="pl-4 list-disc">
                    {driver.previousMotorClaims.map(claim => (
                      <li key={claim.type?.code}>
                        {claim.type?.description || claim.type?.code} (on{' '}
                        {formatInUKLocalTime('dd/MM/yyyy', claim.date)})
                      </li>
                    ))}
                  </ul>
                ) : (
                  'No'
                )}
              </Card.CardListItem>

              <Card.CardListItem
                label="Driving convictions"
                icon="clipboard"
                testId={`policy-driver_list-item_${index}_convictions`}
              >
                {driver?.motorConvictions && driver?.motorConvictions?.length !== 0 ? (
                  <ul className="pl-4 list-disc">
                    {driver.motorConvictions.map(({ type, date }) => (
                      <li key={type?.code}>
                        {`Code ${type?.code}`} (on{' '}
                        {`${formatInUKLocalTime('dd/MM/yyyy', date)}`})
                      </li>
                    ))}
                  </ul>
                ) : (
                  'No'
                )}
              </Card.CardListItem>
              {!isLastItem && <hr className="my-8 text-main-content-5" />}
            </div>
          );
        })}

        {!noMoreButton && (
          <Button
            id={`${testId}_drivers_moreinfo_button`}
            testId={`${testId}_drivers_moreinfo_button`}
            text="More info"
            variant="bordered"
            onClick={handleClick}
            classNames="mt-6 text-base hover:bg-cta-secondary-hover px-6 h-14 lg:px-8 lg:h-16 w-full sm:w-fit "
          />
        )}
      </Card>
    </section>
  );
};
