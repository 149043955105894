import {
  NavigateWithReferrer,
  useNavigateWithReferrer,
  useSignIn,
} from '@dayinsure/shared';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LoadingSpinner, ErrorDialog, PageContainer } from '@dayinsure/components';
import cup from '../../assets/cup.png';
import { getAcrValues, usePerson, useReferrer } from '../../hooks';

export const Retail = () => {
  const [error, setError] = useState<boolean>(false);
  const navigate = useNavigateWithReferrer();
  const referrer = useReferrer();
  const { isGuest } = usePerson();
  const { signIn } = useSignIn(referrer.name, isGuest);
  const [searchParams] = useSearchParams();
  const params = new URLSearchParams(searchParams);

  const customerId = params.get('customerId');
  const policyId = params.get('policyId');

  useEffect(() => {
    if (policyId && customerId) {
      setTimeout(() => {
        signIn({
          extraQueryParams: {
            acr_values: getAcrValues(referrer, customerId),
          },
          state: {
            quoteIdForReAccess: policyId,
          },
          redirectMethod: 'assign',
          redirect_uri: `${window.origin}/login/redirect`,
        });
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseErrorDialog = () => {
    navigate({
      pathname: `/quote`,
    });
    setError(false);
  };

  if (!customerId && !policyId) {
    return <NavigateWithReferrer to={{ pathname: '/' }} />;
  }

  return (
    <PageContainer>
      <div className="flex flex-col mt-8 mb-16 w-full sm:mt-12 md:mb-24 text-main-content-1">
        <h1
          className="self-center mb-6 text-xl text-center"
          id="payment-processing-heading"
        >
          Loading your details
        </h1>
        <img src={cup} alt="cup" className="mx-auto h-60" />
        <h2 className="self-center mb-8 text-lg text-center">
          Please wait while we load your details
        </h2>
        <LoadingSpinner size="regular" centered />
      </div>
      <ErrorDialog
        open={error}
        onClose={handleCloseErrorDialog}
        title="Something went wrong"
        error="We failed to fetch your policy"
        ctaId="retail-error-cta"
        ctaMessage="Okay"
      />
    </PageContainer>
  );
};
