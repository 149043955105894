import { QuoteJourneyRoutes } from './routes';

export type QuoteJourneyPath =
  | QuoteJourneyRoutes
  | ''
  | `../${QuoteJourneyRoutes}`
  | `../../${QuoteJourneyRoutes}`
  | null;

type JourneyMapEntry = {
  previous: QuoteJourneyPath;
  next: QuoteJourneyPath;
};

class QuoteJourneyMap {
  private initialStep = QuoteJourneyRoutes.RegistrationSearch;

  private journeyMap: Record<QuoteJourneyRoutes, JourneyMapEntry> = {
    [QuoteJourneyRoutes.RegistrationSearch]: {
      previous: null,
      next: QuoteJourneyRoutes.CarDetails,
    },
    [QuoteJourneyRoutes.CarDetails]: {
      previous: QuoteJourneyRoutes.RegistrationSearch,
      next: QuoteJourneyRoutes.CarUsage,
    },
    [QuoteJourneyRoutes.CarUsage]: {
      previous: QuoteJourneyRoutes.CarDetails,
      next: QuoteJourneyRoutes.YourDetails,
    },
    [QuoteJourneyRoutes.YourDetails]: {
      previous: QuoteJourneyRoutes.CarUsage,
      next: QuoteJourneyRoutes.YourHome,
    },
    [QuoteJourneyRoutes.YourHome]: {
      previous: QuoteJourneyRoutes.YourDetails,
      next: QuoteJourneyRoutes.YourLicenceAndHistory,
    },
    [QuoteJourneyRoutes.YourLicenceAndHistory]: {
      previous: QuoteJourneyRoutes.YourHome,
      next: QuoteJourneyRoutes.Drivers,
    },
    [QuoteJourneyRoutes.Drivers]: {
      previous: `../${QuoteJourneyRoutes.YourLicenceAndHistory}`,
      next: QuoteJourneyRoutes.Cover,
    },
    [QuoteJourneyRoutes.AdditionalDriverId]: {
      previous: '',
      next: null,
    },
    [QuoteJourneyRoutes.DriverDetails]: {
      previous: `../../${QuoteJourneyRoutes.Drivers}`,
      next: QuoteJourneyRoutes.DriverLicenceAndHistory,
    },
    [QuoteJourneyRoutes.DriverLicenceAndHistory]: {
      previous: QuoteJourneyRoutes.DriverDetails,
      next: `../../${QuoteJourneyRoutes.Drivers}`,
    },
    [QuoteJourneyRoutes.Cover]: {
      previous: QuoteJourneyRoutes.Drivers,
      next: QuoteJourneyRoutes.YourQuote,
    },
    [QuoteJourneyRoutes.QuoteId]: {
      previous: '',
      next: null,
    },
    [QuoteJourneyRoutes.CarSecurity]: {
      previous: `../../${QuoteJourneyRoutes.Cover}`,
      next: QuoteJourneyRoutes.CarSecurityPolicyConditions,
    },
    [QuoteJourneyRoutes.CarSecurityPolicyConditions]: {
      previous: `../../${QuoteJourneyRoutes.Cover}`,
      next: QuoteJourneyRoutes.YourQuote,
    },
    [QuoteJourneyRoutes.YourQuote]: {
      previous: `../../${QuoteJourneyRoutes.Cover}`,
      next: QuoteJourneyRoutes.Review,
    },
    [QuoteJourneyRoutes.Review]: {
      previous: QuoteJourneyRoutes.YourQuote,
      next: QuoteJourneyRoutes.Payment,
    },
    [QuoteJourneyRoutes.Payment]: {
      previous: QuoteJourneyRoutes.Review,
      // TODO: Redirect to Payment page
      next: null,
    },
    [QuoteJourneyRoutes.PurchaseComplete]: {
      previous: null,
      // TODO: add patment processing as previous, and next route
      next: null,
    },
    [QuoteJourneyRoutes.PolicyConditions]: {
      previous: QuoteJourneyRoutes.YourQuote,
      next: QuoteJourneyRoutes.Review,
    },
    [QuoteJourneyRoutes.Empty]: {
      previous: null,
      next: null,
    },
    [QuoteJourneyRoutes.Pcw]: {
      previous: null,
      next: null,
    },
  };

  getPrevious = (quoteStep: QuoteJourneyRoutes | null): QuoteJourneyPath => {
    if (!quoteStep) {
      return this.initialStep;
    }

    return this.journeyMap[quoteStep].previous;
  };

  getNext = (quoteStep: QuoteJourneyRoutes | null): QuoteJourneyPath => {
    if (!quoteStep) {
      return null;
    }

    return this.journeyMap[quoteStep].next;
  };
}

export const quoteJourneyMap = new QuoteJourneyMap();
