import { Button, FieldError, Label, SelectRadioOption } from '@dayinsure/components';
import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import {
  FormCombobox,
  FormDateField,
  FormRadioSelect,
  FormTextField,
  FormFieldBox,
  FormNumField,
} from '../../../../../../../components';
import { useConvictionTypesQuery, useFocusOnError } from '../../../../../../../hooks';
import { useConvictionFields } from './useConvictionFields';
import { motorConvictionsSchema } from '../../../../../../../forms';
import {
  digitOnlyMask,
  getYesNoOptions,
  isYes,
  parseYupErrors,
} from '../../../../../../../helpers';
import { YesNoAnswer } from '../../../../../../../types';

type ConvictionFormProps = {
  name: string;
  testId: string;
  onCancel: VoidFunction;
  shouldDisplayCancelButton: boolean;
};

export const ConvictionForm = ({
  name,
  testId,
  shouldDisplayCancelButton,
  onCancel,
}: ConvictionFormProps) => {
  const fieldRef = useRef<HTMLDivElement | null>(null);
  const { data } = useConvictionTypesQuery();
  const [query, setQuery] = useState('');

  const {
    conviction,
    code,
    isSaved,
    banReceived,
    disqualifiedLengthInMonthsField,
    setFieldTouched,
    setFieldError,
    setFieldValue,
  } = useConvictionFields(name);
  useFocusOnError({ fieldRef, name: isSaved.fieldName });
  const invalidateField = ({ path, message }: { path: string; message: string }) => {
    setFieldTouched(`${name}.${path}`, true);
    setFieldError(`${name}.${path}`, message);
  };

  const handleChangeBanReceived = (option: SelectRadioOption) => {
    if (option.id === YesNoAnswer.NO) {
      setFieldTouched(disqualifiedLengthInMonthsField.fieldName, false);
      setFieldError(disqualifiedLengthInMonthsField.fieldName, undefined);
      setFieldValue(disqualifiedLengthInMonthsField.fieldName, null);
    }
  };

  const handleSave = () => {
    motorConvictionsSchema
      .validate(conviction.value, { abortEarly: false })
      .then(() => {
        setFieldValue(isSaved.fieldName, true);
      })
      .catch(errors => {
        const parsedErrors = parseYupErrors(errors);
        parsedErrors.forEach(invalidateField);
      });
  };

  const comboboxOptions = useMemo(
    () =>
      data
        ?.filter(elem => elem.code?.toLowerCase().includes(query.toLowerCase()))
        .map(elem => ({
          id: elem.code || '',
          name: elem.code || '',
          description: elem.description ?? `${elem.description}`,
        })),
    [data, query]
  );

  const hasFieldErrors =
    !!code.meta.error ||
    !!banReceived.meta.error ||
    !!disqualifiedLengthInMonthsField.meta.error;

  const isSavedError =
    isSaved.meta.touched && !!isSaved.meta.error && !hasFieldErrors
      ? isSaved.meta.error
      : undefined;

  useLayoutEffect(() => {
    setFieldTouched(isSaved.fieldName, hasFieldErrors);
  }, [hasFieldErrors, isSaved.fieldName, setFieldTouched]);

  return (
    <div data-testid={testId} className="relative">
      <FormFieldBox>
        <FormCombobox
          label={{
            icon: 'code',
            text: 'Conviction code',
          }}
          id={`${testId}_code-combobox`}
          name={code.fieldName}
          placeholder="Enter code"
          noOptionsMessage="No options"
          filteredOptions={comboboxOptions || []}
          onQueryChange={setQuery}
          itemDescriptionOnly
        />
      </FormFieldBox>
      <FormFieldBox>
        <FormDateField
          label={{
            icon: 'date',
            text: 'Date of conviction',
          }}
          testId={`${testId}_date-of-conviction-input`}
          id={`${testId}_date-of-conviction-input`}
          name={`${name}.date`}
          configuration={{
            day: {
              enabled: true,
              placeholder: 'DD',
            },
            month: {
              placeholder: 'MM',
            },
            year: {
              placeholder: 'YYYY',
            },
          }}
        />
      </FormFieldBox>
      <FormFieldBox>
        <FormNumField
          label={{ text: 'Penalty points', icon: 'penalty' }}
          testId={`${testId}_penalty-points-input`}
          id={`${testId}_penalty-points-input`}
          name={`${name}.penaltyPoints`}
          tooltip="This is the number of points received for this conviction only, and not the total number of points on your licence."
          placeholder="Enter amount"
          min={1}
          max={36}
          onInput={digitOnlyMask}
        />
      </FormFieldBox>
      <FormFieldBox>
        <FormNumField
          label={{ text: 'Fine amount', icon: 'wallet' }}
          testId={`${testId}_fine-amount-input`}
          id={`${testId}_fine-amount-input`}
          name={`${name}.fineAmount`}
          type="number"
          placeholder="Enter amount (£)"
          min={1}
          max={9999}
          onInput={digitOnlyMask}
        />
      </FormFieldBox>
      <FormFieldBox>
        <Label
          className="mb-4"
          htmlFor={`${testId}_ban-received-radio`}
          icon="ban"
          name="Ban received?"
          testId={`${testId}_ban-received-radio_label`}
        />
        <FormRadioSelect
          id={`${testId}_ban-received-radio`}
          testId={`${testId}_ban-received-radio`}
          name={`${name}.banReceived`}
          onChangeOptional={handleChangeBanReceived}
          options={getYesNoOptions(`${testId}_ban-received`)}
        />
      </FormFieldBox>
      {isYes(banReceived.value?.id) && (
        <FormFieldBox>
          <FormTextField
            label={{ text: 'Length of ban in months', icon: 'date' }}
            testId={`${testId}_ban-length-input`}
            id={`${testId}_ban-length-input`}
            name={`${name}.disqualifiedLengthInMonths`}
            type="number"
            placeholder="Enter number of months"
            onInput={digitOnlyMask}
          />
        </FormFieldBox>
      )}
      <div className="flex relative gap-2" ref={fieldRef}>
        <Button
          variant="bordered"
          id={`${testId}_add-button`}
          testId={`${testId}_add-button`}
          onClick={handleSave}
          text="Add this conviction"
        />
        {shouldDisplayCancelButton && (
          <Button
            variant="bordered"
            id={`${testId}_cancel-button`}
            testId={`${testId}_cancel-button`}
            onClick={onCancel}
            text="Cancel"
          />
        )}
      </div>
      {isSavedError && (
        <FieldError
          id={`${name}-error`}
          testId={`${testId}_error`}
          error={isSavedError}
          className="absolute left-0 mt-2"
        />
      )}
    </div>
  );
};
