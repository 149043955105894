import { ContentContainer, Card, Icon } from '@dayinsure/components';
import { Form, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import { BackButton, FormCheckbox, MtaChangeButtons } from '../../../../components';
import { InfoCard } from './InfoCard';
import { PaymentType } from '../../../Quote/YourQuote/PaymentType';
import {
  formatNegativePrice,
  getCurrentPaymentPlan,
  isMonthlyPaymentPlan,
  toFixedString,
} from '../../../../helpers';
import { MtaJourneyFormData } from '../../../../types/mtaJourneyForm';
import { usePaymentInfo } from '../../../../hooks';
import { PaymentCard } from '../../../../components/PaymentCard';
import { getNewCostBackLink } from './NewCost.utils';
import { AddOnModelDto, GetMotorQuoteResponseDto } from '../../../../api/v1';

type InsurancePriceKey = 'netPremium' | 'total' | 'insurancePremiumTax';
const testId = 'mta-new-cost';
const increaseText =
  'Making these changes means the cost of your policy will <strong>increase</strong> by ';
const endingText = 'This amount does not include any cost for credit.';
const decreaseText =
  'Making these changes means the cost of your policy will <strong>decrease</strong> by ';
const equalText =
  'Making these changes means the cost of your policy will <strong>remain the same</strong>';

const getPrice = (
  data: GetMotorQuoteResponseDto | undefined,
  key: InsurancePriceKey,
  showSign = false
): string => {
  const amount = data?.insurancePrice?.[key]?.amount;
  const price = `£${toFixedString(amount)}`;
  if (amount === 0) {
    return '£0';
  }
  if (amount && amount > 0) {
    return showSign ? `+${price}` : price;
  }
  const sign = showSign ? '-' : '';
  return `${sign}${price.replace('-', '')}`;
};

const getNCBCostDifference = (data: GetMotorQuoteResponseDto | undefined) => {
  const protectedNCBAddon = data?.addOns?.find(
    (addOn: AddOnModelDto) => addOn.selected && addOn.type?.code === 'PROTECTED_NCB'
  );
  if (protectedNCBAddon) {
    const amount = protectedNCBAddon?.price?.total?.amount;
    const price = `£${toFixedString(Math.abs(Number(amount)))}`;
    if (amount === 0) {
      return '£0';
    }
    if (amount && amount > 0) {
      return `+${price}`;
    }
    if (amount && amount < 0) {
      return `-${price}`;
    }
  }
  return null;
};
export const NewCost = () => {
  const [monthlyEqual, setMonthlyEqual] = useState(false);
  const [cardInfoMessage, setCardInfoMessage] = useState<string>('');
  const [isRefund, setIsRefund] = useState<boolean>(false);
  const { values, setFieldValue } = useFormikContext<MtaJourneyFormData>();
  const { isLoading, data } = usePaymentInfo();
  const { id } = useParams<{ id: string }>();
  const currentPlan = getCurrentPaymentPlan(
    values.usualPaymentFrequency,
    data?.paymentPlans
  );
  const isMonthlyPlan = isMonthlyPaymentPlan(currentPlan?.type?.code);
  const hasEndorsments202or208 =
    !!values?.carSecurity?.isEndorsement202 || !!values?.carSecurity?.isEndorsement208;
  const checkboxPath = 'newCostAgreement';
  const isCheckboxSelected = values[checkboxPath];
  const ncbCostDifference = getNCBCostDifference(data);

  useEffect(() => {
    const costDifference = isMonthlyPlan
      ? currentPlan?.installmentDetails?.financeAmount?.amount
      : currentPlan?.installmentDetails?.total?.amount;
    if (typeof costDifference === 'number') {
      if (costDifference === 0) {
        setCardInfoMessage(equalText);
        if (isMonthlyPlan) {
          setMonthlyEqual(true);
        } else {
          setMonthlyEqual(false);
        }
      } else if (costDifference < 0) {
        setMonthlyEqual(false);
        setCardInfoMessage(
          `${decreaseText} <strong>£${toFixedString(Math.abs(costDifference))}</strong>. ${
            isMonthlyPlan ? endingText : ''
          }`
        );
        setFieldValue('isRefund', true);
        setIsRefund(true);
      } else {
        setMonthlyEqual(false);
        setCardInfoMessage(
          `${increaseText} <strong>£${toFixedString(costDifference)}</strong>. ${
            isMonthlyPlan ? endingText : ''
          }`
        );
      }
    }
  }, [
    currentPlan?.installmentDetails?.financeAmount?.amount,
    currentPlan?.installmentDetails?.total?.amount,
    isMonthlyPlan,
    setFieldValue,
  ]);

  useEffect(() => {
    setFieldValue(checkboxPath, false);
  }, [setFieldValue]);

  if (!id || isLoading) {
    return null;
  }
  const selectPlanFromApi = (plan: { id: string; name: string }) => {
    setFieldValue('usualPaymentFrequency.code', plan);
  };

  return (
    <div className="font-raleway">
      <BackButton
        customBackLink={getNewCostBackLink(hasEndorsments202or208, values.policyChange)}
      />
      <ContentContainer>
        <h1
          className="my-8 text-xl text-center md:my-12 lg:my-16 text-main-content-1"
          data-testid={`${testId}_form-title`}
        >
          Your new cost
        </h1>
        {cardInfoMessage && !monthlyEqual && <InfoCard costMessage={cardInfoMessage} />}
        <Card paddingLevel="small" classNames="mt-6 lg:mt-8 text-sm">
          <h2 className="pb-4 text-lg">Cost for changes</h2>

          {data?.insurancePrice?.fees && data?.insurancePrice?.fees?.[0].total?.amount && (
            <div className="flex justify-between">
              <div>Amendment fee</div>
              <div className="font-bold">
                {formatNegativePrice(data?.insurancePrice?.fees?.[0].total?.amount)}
              </div>
            </div>
          )}

          {typeof data?.insurancePrice?.insurancePremiumTax?.amount === 'number' && (
            <div className="flex justify-between">
              <div>Insurance premium tax</div>
              <div className="font-bold">
                {formatNegativePrice(data?.insurancePrice?.insurancePremiumTax?.amount)}
              </div>
            </div>
          )}

          <div className="flex justify-between">
            <div>Policy cost difference</div>
            <div className="font-bold">{getPrice(data, 'netPremium', true)}</div>
          </div>
          {ncbCostDifference && (
            <div className="flex justify-between">
              <div>Protected NCB cost difference</div>
              <div className="font-bold">{ncbCostDifference}</div>
            </div>
          )}
          {typeof currentPlan?.totalCost?.amount === 'number' && (
            <div className="flex justify-between">
              <div>Total cost for changes</div>
              <div className="font-bold">
                {formatNegativePrice(currentPlan?.totalCost?.amount)}
              </div>
            </div>
          )}
        </Card>

        <Form>
          {data && data?.paymentPlans && (
            <>
              <PaymentType
                disabled={isLoading}
                paymentPlans={data.paymentPlans}
                insurencePrice={data.insurancePrice}
                totalPriceIncAddOns={data.totalPriceIncAddOns}
                testId={testId}
                title="Payment method"
                showTooltip={false}
                isMta
                isRefund={isRefund}
                onChangeOptional={selectPlanFromApi}
              />
            </>
          )}
          {isRefund && !isMonthlyPlan && (
            <Card
              classNames="!bg-popup-background text-left font-railway text-popup-content-1 flex items-center gap-4 md:gap-6 mb-6 lg:mb-10"
              paddingLevel="small"
            >
              <Icon name="warning" size="2rem" className="text-popup-content-1" />
              <p>
                This amount will be refunded to the original card(s) you paid with,
                normally within 5 to 7 working days.
              </p>
            </Card>
          )}
          {values.usualPaymentFrequency.code?.id && data && (
            <PaymentCard
              addOns={data.addOns}
              insurancePrice={data.insurancePrice}
              paymentPlans={data.paymentPlans}
              cover={data.cover}
              totalPriceIncAddOns={data.totalPriceIncAddOns}
              excesses={data.excesses}
              disabled={isLoading}
              testId={testId}
              mta
              isRefund={isRefund}
              usualPaymentFrequency={values.usualPaymentFrequency}
            />
          )}

          <Card
            classNames={clsx('flex items-center', 'mb-12', 'mt-8', 'lg:mt-12')}
            paddingLevel="none"
            testId={`${testId}_agreement-box`}
          >
            <FormCheckbox
              displayText="I confirm the details are accurate and will inform you if they change."
              name={checkboxPath}
              id={`${testId}_agreement-input`}
              testId={`${testId}_agreement-input`}
              noBorders
            />
          </Card>

          <MtaChangeButtons
            disabled={
              !values?.usualPaymentFrequency?.code || isLoading || !isCheckboxSelected
            }
          />
        </Form>
      </ContentContainer>
    </div>
  );
};
