import { Card, Icon } from '@dayinsure/components';
import { formatInUKLocalTime } from '@dayinsure/shared';
import { AccidentOrClaimType } from '../../../../../../../types';
import { transformForCommaAndNoCommaValue } from '../../../../../../../helpers';

type AccidentOrClaimItemProps = {
  accidentOrClaim: AccidentOrClaimType;
  testId: string;
  onRemoveAccidentOrClaim: () => void;
};

export const AccidentOrClaimCard = ({
  accidentOrClaim,
  testId,
  onRemoveAccidentOrClaim,
}: AccidentOrClaimItemProps) => {
  const doClaimAmountExist = typeof accidentOrClaim?.claimAmount === 'number';
  const claimAmounWithComma = doClaimAmountExist
    ? transformForCommaAndNoCommaValue(String(accidentOrClaim?.claimAmount))
    : null;
  return (
    <Card
      classNames="mb-6 md:mb-12"
      key={accidentOrClaim.claimDate?.parsedDate}
      testId={`${testId}_item`}
    >
      <div className="flex justify-between items-start">
        <Card.CardListItem
          className="mt-0"
          label="Type of claim"
          icon="clipboard"
          iconSize="0.75rem"
          testId={`${testId}_card_type-of-claim`}
        >
          {accidentOrClaim.selfDeclaredClaimType?.name}
        </Card.CardListItem>
        <button
          className="flex items-center text-sm font-semibold leading-[14px] text-link"
          data-testid={`${testId}_card_remove-claim-button`}
          onClick={onRemoveAccidentOrClaim}
          type="button"
        >
          <Icon name="remove" className="mr-2" size="12px" />
          Remove
        </button>
      </div>
      <Card.CardListItem
        label="Date of claim"
        icon="date"
        iconSize="0.75rem"
        testId={`${testId}_card_date-of-claim`}
      >
        {formatInUKLocalTime('dd/MM/yyyy', accidentOrClaim.claimDate?.parsedDate)}
      </Card.CardListItem>
      <Card.CardListItem
        label="At fault"
        icon="user-group"
        iconSize="0.75rem"
        testId={`${testId}_card_claim-fault`}
      >
        {accidentOrClaim.fault?.name}
      </Card.CardListItem>
      <Card.CardListItem
        label="Claim settled"
        icon="legal"
        iconSize="0.75rem"
        testId={`${testId}_card_claim-settled`}
      >
        {accidentOrClaim.claimSettled?.name}
      </Card.CardListItem>
      {doClaimAmountExist && (
        <Card.CardListItem
          label="Claim amount"
          icon="wallet"
          iconSize="0.75rem"
          testId={`${testId}_card_claim-amount`}
        >
          {`£${claimAmounWithComma?.valueWithComa}` || 0}
        </Card.CardListItem>
      )}
    </Card>
  );
};
