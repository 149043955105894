import { FormikHelpers } from 'formik';
import { usePerson } from '../../usePerson';
import { QuoteJourneyFormData } from '../../../types';
import { useCloneToProposerSubmitHandler } from '../../useCloneToProposerSubmitHandler';
import { useMarketingConsentCreate } from '../../mutations';

export const useYourDetailSubmit = () => {
  const updateMarketingConsent = useMarketingConsentCreate();
  const { person, setPerson } = usePerson();
  const cloneToProposer = useCloneToProposerSubmitHandler(
    ['firstName', 'lastName', 'title', 'dateOfBirth', 'maritalStatus'],
    'proposer',
    'drivers[0]'
  );

  return async (
    values: QuoteJourneyFormData,
    formikHelpers: FormikHelpers<QuoteJourneyFormData>,
    nextSubmitHandler: (formikHelpers: FormikHelpers<QuoteJourneyFormData>) => void
  ) => {
    cloneToProposer(values, formikHelpers);

    if (person) {
      const wasEmailChanged = person.email !== values.proposer.email;
      formikHelpers.setFieldValue('wasEmailChangedAfterCreation', wasEmailChanged);

      if (wasEmailChanged) {
        setPerson(null);
        formikHelpers.setFieldValue('isAccountCreated', false);
        formikHelpers.setFieldValue('signUp.password', '');
        formikHelpers.setFieldValue('signUp.retypePassword', '');
        formikHelpers.setFieldValue('signUp.reaptcha', '');

        formikHelpers.setFieldTouched('signUp.password', false);
        formikHelpers.setFieldTouched('signUp.retypePassword', false);
        formikHelpers.setFieldTouched('signUp.reaptcha', false);
      }

      updateMarketingConsent.mutate(values.proposer.marketingPreferences);
    }
    nextSubmitHandler(formikHelpers);
  };
};
