import { useState, useEffect, useRef } from 'react';
import { ContentContainer } from '@dayinsure/components';
import { formatInUKLocalTime } from '@dayinsure/shared';
import { useParams } from 'react-router-dom';
import { PoliciesPaths } from '../../../../routes';
import { ContactCards, Loader, PolicySummaryCard } from '../../../../components';
import {
  useMotorPolicySummaryQuery,
  usePolicyQuery,
  useResetFormState,
} from '../../../../hooks';
import { PolicyDocuments } from '../../../../components/PolicyDocuments';
import { CardWithLogin } from '../../../../components/CardWithLogin';
import { hasRACAddOn } from '../../../../helpers/policy/hasRACAddOn';
import { PolicyPendingChangesMessage } from '../../PolicyInfo/PolicyInfoPendingChanges';
import { RefundCard } from './RefundCard/RefundCard';

const testId = 'changes-complete';

export const ChangesComplete = () => {
  useResetFormState();
  const [version, setVersion] = useState<number | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { quoteId, id } = useParams<{ quoteId: string; id: string }>();
  const { data: policy, refetch: refetchPolicy } = usePolicyQuery(id, undefined, true);
  const { data: upcomingPolicy, refetch: refetchUpcomingPolicy } = usePolicyQuery(
    id,
    version,
    true
  );
  const { data } = useMotorPolicySummaryQuery(quoteId);
  const motorQuote = data?.[0];
  const isRefund = (upcomingPolicy?.snapshotTransaction?.price?.total?.amount || 0) < 0;
  const fetchIntervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    if (policy) {
      if (
        (!policy.pendingChanges || !upcomingPolicy?.snapshotTransaction) &&
        !fetchIntervalRef.current
      ) {
        fetchIntervalRef.current = setInterval(() => {
          refetchPolicy();
          refetchUpcomingPolicy();
        }, 500);
      } else {
        if (fetchIntervalRef.current) {
          clearInterval(fetchIntervalRef.current);
        }
        setIsLoading(false);
      }
    }
  }, [policy, refetchPolicy, refetchUpcomingPolicy, upcomingPolicy?.snapshotTransaction]);

  useEffect(() => {
    if (!version && policy?.pendingChanges?.policyVersion) {
      setVersion(policy.pendingChanges.policyVersion);
    }
  }, [policy?.pendingChanges?.policyVersion, version]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="mb-8 w-full md:mb-16">
      <ContentContainer>
        <h1
          className="mt-8 text-xl text-center md:mt-12 text-main-content-1 lg:mt-15"
          data-testid={`${testId}_title`}
        >
          Changes complete
        </h1>
        <h2
          className="my-8 text-lg text-center md:mt-12 lg:mt-12 text-main-content-1"
          data-testid={`${testId}_subtitle`}
        >
          Thank you for using us for your car insurance
        </h2>
        {policy?.pendingChanges && policy?.pendingChanges?.effectiveDateTimeUtc && (
          <PolicyPendingChangesMessage
            testId={testId}
            changesStartDateUtc={policy?.pendingChanges?.effectiveDateTimeUtc}
            policyVersion={policy?.pendingChanges?.policyVersion}
            customNavLink={`../../../${PoliciesPaths.UpcomingChanges}`}
          />
        )}
        {motorQuote && (
          <PolicySummaryCard
            plateNo={motorQuote.vehicleRegistration || ''}
            carDetails={motorQuote.vehicleDescription || ''}
            progress={motorQuote.policyProgress || 0}
            buttonLink={`/${PoliciesPaths.Policies}/${id}`}
            testId={testId}
            activityDate={`Ends ${formatInUKLocalTime(
              'dd/MM/yyyy',
              motorQuote.endDate
            )} at ${formatInUKLocalTime('HH:mm', motorQuote.endDate)}`}
            className="bg-content-background-prominent"
          />
        )}
        {isRefund && <RefundCard testId={testId} />}
        <CardWithLogin testId={`${testId}_info-documents`} />
        {motorQuote?.id && (
          <PolicyDocuments
            className="mt-8 lg:mt-12"
            testId={testId}
            policyId={motorQuote?.id}
          />
        )}
        <ContactCards testId={testId} displayRAC={hasRACAddOn(policy?.addOns)} />
      </ContentContainer>
    </div>
  );
};
