import { formatInUKLocalTime } from '@dayinsure/shared';
import { PolicyState } from '../../../api/v1';

export type PolicyActivityDate = {
  state?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  cancelledDate?: string | null;
};

export const getActivityDate = (policy?: PolicyActivityDate | null) => {
  if (policy?.state) {
    switch (policy.state) {
      case PolicyState.ACTIVE:
        return `Ends ${formatInUKLocalTime(
          'dd/MM/yyyy',
          policy.endDate
        )} at ${formatInUKLocalTime('HH:mm', policy.endDate)}`;
      case PolicyState.UPCOMING:
        return `Starts ${formatInUKLocalTime(
          'dd/MM/yyyy',
          policy.startDate
        )} at ${formatInUKLocalTime('HH:mm', policy.startDate)}`;
      case PolicyState.CANCELLED:
        return `Cancelled ${formatInUKLocalTime(
          'dd/MM/yyyy',
          policy.cancelledDate
        )} at ${formatInUKLocalTime('HH:mm', policy.cancelledDate)}`;
      case PolicyState.EXPIRED:
        return `Ended  ${formatInUKLocalTime(
          'dd/MM/yyyy',
          policy.endDate
        )} at ${formatInUKLocalTime('HH:mm', policy.endDate)}`;
      default:
        return '';
    }
  }
  return '';
};
