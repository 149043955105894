import { GlobalErrorContext } from '@dayinsure/shared';
import { useContext, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import {
  ApiError,
  Dayinsure as DayinsureAPIv1,
  MotorPolicyDto,
  OpenAPI,
} from '../../../api/v1';
import { query } from '../queryKeys';

export const usePolicyQuery = (
  id: string | undefined | null,
  version?: number | undefined,
  freshData?: boolean
) => {
  const queryClient = useQueryClient();
  const { setGlobalError } = useContext(GlobalErrorContext);

  useEffect(() => {
    if (freshData) {
      queryClient.invalidateQueries(query.policy(id || '', version));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPolicy = () => {
    if (!id) {
      throw new Error('No policy ID');
    }
    const api = new DayinsureAPIv1(OpenAPI);
    return api.motorPolicy.getApiV1MotorPolicy(id, version);
  };

  return useQuery<MotorPolicyDto, ApiError>(query.policy(id || '', version), getPolicy, {
    keepPreviousData: true,
    enabled: id !== undefined,
    onError: err => {
      if (err.status !== 404) {
        setGlobalError(err);
      }
    },
  });
};
