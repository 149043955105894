import { Card, Icon } from '@dayinsure/components';
import { useState } from 'react';
import { formatInUKLocalTime } from '@dayinsure/shared';
import { MotorPolicyTransactionDto } from '../../../../api/v1';
import { PaymentDetailModal } from '../PaymentDetailModal';
import { fixNegativePriceDisplay, isMonthlyPaymentPlan } from '../../../../helpers';

const getTitleFromTransactionType = (transactionType: string) => {
  switch (transactionType.toLowerCase()) {
    case 'NewBusiness'.toLowerCase():
      return 'New policy';
    case 'MidTermAdjustment'.toLowerCase():
      return 'Policy changes';
    case 'Cancellation'.toLowerCase():
      return 'Policy cancellation';
    case 'Reversal'.toLowerCase():
      return '';
    default:
      return transactionType;
  }
};

type PolicyPaymentItemProps = {
  transaction: MotorPolicyTransactionDto;
  testId: string;
};

export const PolicyPaymentItem = ({ transaction, testId }: PolicyPaymentItemProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isMonthly = isMonthlyPaymentPlan(transaction.paymentPlan?.type?.code);
  const handleOpenModal = () => setIsOpen(true);
  const handleCloseModal = () => setIsOpen(false);

  return (
    <Card testId={`${testId}_payment-item-card`} classNames="mb-6">
      {transaction.type && (
        <h3 className="text-lg">{getTitleFromTransactionType(transaction.type)}</h3>
      )}
      {isMonthly && (
        <Card.CardListItem
          icon="card"
          label="Payment type"
          testId={`${testId}_payment-item-card_payment-type`}
        >
          {`Monthly payments (x${transaction.paymentPlan?.installmentDetails?.numberOfInstallments})`}
        </Card.CardListItem>
      )}
      <Card.CardListItem
        icon="policy"
        label="Payment amount"
        testId={`${testId}_payment-item-card_amount`}
      >
        {isMonthly
          ? `£${transaction.paymentPlan?.installmentDetails?.monthlyCost?.amount?.toFixed(
              2
            )} /month`
          : fixNegativePriceDisplay(transaction.price?.total?.amount)}
      </Card.CardListItem>
      <Card.CardListItem
        icon="date"
        label="Payment date"
        testId={`${testId}_payment-item-card_payment-date`}
      >
        {formatInUKLocalTime('dd/MM/yyyy', transaction.createdDateTimeUtc)}
      </Card.CardListItem>
      <button
        className="flex items-center mt-4 text-sm font-semibold leading-[14px] text-cta-secondary-text"
        data-testid={`${testId}_edit-button`}
        onClick={handleOpenModal}
        type="button"
      >
        <Icon name="info" className="mr-1" size="12px" />
        View payment details
      </button>
      <PaymentDetailModal
        transaction={transaction}
        isOpen={isOpen}
        testId={testId}
        onClose={handleCloseModal}
      />
    </Card>
  );
};
