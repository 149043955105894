import { Card, Icon } from '@dayinsure/components';
import clsx from 'clsx';

type RefundCardProps = {
  testId: string;
};
export const RefundCard = ({ testId }: RefundCardProps) => {
  return (
    <section>
      <Card
        classNames={clsx('flex items-center', 'mb-8', 'mt-8 lg:mt-12')}
        paddingLevel="small"
        testId={`${testId}_refund_card`}
      >
        <Icon className="text-main-content-1" size="2rem" name="warning" />
        <div className="ml-6 text-main-content-1">
          The refund amount will be refunded to the original card(s) you paid with,
          normally within 3 to 5 working days.
        </div>
      </Card>
    </section>
  );
};
