import { Card, Icon } from '@dayinsure/components';

type CardValueWarningCardProps = {
  testId: string;
};

export const CardValueWarningCard = ({ testId }: CardValueWarningCardProps) => {
  return (
    <Card
      testId={`${testId}_car-value-warning-card`}
      classNames="flex items-center gap-4 md:gap-6 text-left mt-8 md:mt-12"
    >
      <Icon name="warning" size="2rem" className="text-popup-content-1" />
      <span className="font-raleway">
        The voluntary excess you’ve chosen means the value of your car is less than or
        equal to your total excess. If you make a fault claim for theft or damage to your
        car only, you may not get any money back for your car. Please check the voluntary
        excess you have chosen.
      </span>
    </Card>
  );
};
