import { FormTooltip } from '../../../../../components';

type NonMotoringConvictionsTootltipProps = {
  testId: string;
};

export const NonMotoringConvictionsTootltip = ({
  testId,
}: NonMotoringConvictionsTootltipProps) => (
  <FormTooltip testId={`${testId}_non-motoring-convictions`}>
    <h4 className="mb-2 font-bold">Non-motoring criminal convictions</h4>
    <p>
      Convictions considered to be spent under the Rehabilitation of Offenders Act 1974 DO
      NOT need to be disclosed as they can effectively be ignored after a specified period
      of time. There are some offences which are never spent, and these must be declared.
      If you&apos;re based in England or Wales, you can use Unlock&apos;s criminal record
      disclosure calculator (
      <a
        href="https://disclosurecalculator.org.uk"
        target="_blank"
        rel="noreferrer"
        className="font-semibold underline text-link"
      >
        www.disclosurecalculator.org.uk
      </a>
      ) to work out whether your convictions are spent.
    </p>
  </FormTooltip>
);
