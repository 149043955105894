import { Button, Card } from '@dayinsure/components';
import { useNavigateWithReferrer } from '@dayinsure/shared';
import { MotorPolicyTransactionDto } from '../../../../api/v1';
import { isMonthlyPaymentPlan, isRefund } from '../../../../helpers';
import { PoliciesPaths } from '../../../../routes';

const getAnnualPaymentTypeDescription = (
  refund: boolean,
  isUpcimingChange?: boolean
): string => {
  if (isUpcimingChange) {
    return refund ? 'One-off refund' : 'One-off payment';
  }

  return 'Annually';
};

type PolicyInfoPaymentsProps = {
  displayMoreinfoButton: boolean;
  transaction: MotorPolicyTransactionDto;
  isUpcimingChange?: boolean;
  testId?: string;
};

export const PolicyInfoPayments = ({
  displayMoreinfoButton,
  isUpcimingChange,
  transaction,
  testId,
}: PolicyInfoPaymentsProps) => {
  const navigate = useNavigateWithReferrer();
  const handleClick = () => {
    navigate(PoliciesPaths.Payments);
  };
  const isMonthly = isMonthlyPaymentPlan(transaction.paymentPlan?.paymentFrequency?.code);
  const isAnnual = !isMonthly;
  const totalPrice =
    transaction.paymentPlan?.installmentDetails?.total?.amount &&
    Math.abs(transaction.paymentPlan?.installmentDetails?.total?.amount).toFixed(2);
  const refund = isRefund(transaction.paymentPlan);

  return (
    <Card
      paddingLevel="small"
      classNames="bg-content-background-prominent mb-6"
      testId={`${testId}_payments_card`}
    >
      <h2 className="mb-4 font-raleway text-lg">Payments</h2>
      <Card.CardListItem
        label="Payment type"
        testId={`${testId}_payments_payment-type_list-item`}
        icon="card"
      >
        {isMonthly
          ? 'Monthly'
          : getAnnualPaymentTypeDescription(refund, isUpcimingChange)}
      </Card.CardListItem>

      {isUpcimingChange && (
        <Card.CardListItem
          testId={`${testId}_payments_policy-payments_list-item`}
          label="Payments"
          icon="policy"
        >
          {isMonthly &&
            `${
              transaction.paymentPlan?.installmentDetails?.numberOfInstallments
            } monthly payments £${transaction.paymentPlan?.installmentDetails?.monthlyCost?.amount?.toFixed(
              2
            )}`}
          {isAnnual && `£${totalPrice}`}
        </Card.CardListItem>
      )}

      {displayMoreinfoButton && (
        <Button
          id={`${testId}_payment_moreinfo_button`}
          testId={`${testId}_payment_moreinfo_button`}
          text="More info"
          variant="bordered"
          onClick={handleClick}
          classNames="mt-6 text-base hover:bg-cta-secondary-hover px-6 h-14 lg:px-8 lg:h-16 w-full sm:w-fit "
        />
      )}
    </Card>
  );
};
