import { fixYearsDispley } from './fixYearsDisplay';

export const getLicenceHeldDescription = (years?: number, months?: number): string => {
  if (years === 0 && (months || months === 0)) {
    return months !== 1 ? `${months} months` : `${months} month`;
  }
  return (years || years === 0) && years > 0 && years !== 1
    ? `${fixYearsDispley(years)} years`
    : `${years} year`;
};
