import { Button, Card, ContentContainer, Icon } from '@dayinsure/components';
import clsx from 'clsx';
import { Form, useFormikContext } from 'formik';
import { useNavigateWithReferrer } from '@dayinsure/shared';
import { ReactNode, useLayoutEffect, useState } from 'react';
import { ConditionsCard } from '../../../components/Quote';
import { QuoteJourneyLayout, FormCheckbox, Link } from '../../../components';
import { QuoteJourneyFormData } from '../../../types';
import { quoteJourneyMap, QuoteJourneyRoutes } from '../../../routes';

const testId = 'policy-endorsements';

type PolicyConditionsProps = {
  conditionsCodesToDisplay?: string[];
  checkboxPath?: keyof QuoteJourneyFormData;
  customBackLink?: string;
  customButtonText?: string;
  customSecondaryButton?: ReactNode;
};

export const PolicyConditions = ({
  conditionsCodesToDisplay,
  checkboxPath = 'policyConditions',
  customBackLink,
  customButtonText,
  customSecondaryButton,
}: PolicyConditionsProps) => {
  const [ready, setReady] = useState(false);
  const { setFieldValue } = useFormikContext<QuoteJourneyFormData>();
  const navigate = useNavigateWithReferrer();
  const backLink =
    customBackLink ||
    `../${quoteJourneyMap.getPrevious(QuoteJourneyRoutes.PolicyConditions)}`;
  const handleGoBack = () => navigate(backLink);
  const { values } = useFormikContext<QuoteJourneyFormData>();

  const isDisabled = !values[checkboxPath];
  useLayoutEffect(() => {
    setFieldValue(checkboxPath, false);
    setReady(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <QuoteJourneyLayout showBackButton customeBackLink={backLink}>
      {ready && (
        <ContentContainer>
          <h1 className="mt-8 text-xl text-center md:mt-12 text-main-content-1 lg:mt-15">
            Policy endorsements
          </h1>
          <Card
            classNames={clsx('flex items-center', 'mb-12', 'mt-6', 'lg:mt-12')}
            paddingLevel="small"
            testId={`${testId}_RAC-phone`}
          >
            <Icon className="text-main-content-1" size="2rem" name="warning" />
            <div className="ml-6 text-main-content-1">
              There are endorsements (also known as conditions), that will be applied to
              your policy. A summary of these is shown below. For full terms and
              conditions of each endorsement, please see page 24 of your Policy Wording.{' '}
              <Link href="https://didevwebresources.blob.core.windows.net/resources/DocumentStore/Annual/Everydayinsure/PolicyWording/EDI%20Motor%20Insurance%20Policy%20v1.0.pdf">
                Click here
              </Link>{' '}
              to see the full terms.
            </div>
          </Card>
          <ConditionsCard
            conditionsCodesToDisplay={conditionsCodesToDisplay}
            testId={testId}
          />
          <Form>
            <Card
              classNames={clsx('flex items-center', 'mb-16', 'mt-6', 'lg:mt-12')}
              paddingLevel="none"
              testId={`${testId}_RAC-phone`}
            >
              <FormCheckbox
                displayText="I agree to all the endorsements shown"
                name={checkboxPath}
                id={`${testId}_input`}
                testId={`${testId}_input`}
                noBorders
              />
            </Card>
            <div className="mb-8">
              <Button
                id={`${testId}_agree-and-continue_button`}
                testId={`${testId}_agree-and-continue_button`}
                submit
                fullWidth
                text="Agree and continue"
                disabled={isDisabled}
              />
            </div>
            <div className="mb-16">
              {customSecondaryButton || (
                <Button
                  onClick={handleGoBack}
                  id={`${testId}_go-back_button`}
                  testId={`${testId}_go-back_button`}
                  fullWidth
                  text={customButtonText || 'Go back to quote'}
                  variant="secondary"
                />
              )}
            </div>
          </Form>
        </ContentContainer>
      )}
    </QuoteJourneyLayout>
  );
};
