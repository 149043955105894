import { GlobalErrorContext } from '@dayinsure/shared';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { ApiError, DocumentDto, OpenAPI } from '../../../api/v2';
import { query } from '../queryKeys';
import { Dayinsure as DayinsureAPIv1 } from '../../../api/v1';

export const useDocumentsQuery = (id: string) => {
  const { setGlobalError } = useContext(GlobalErrorContext);

  const getDocumentsLinks = () => {
    if (!id) {
      throw Error('No policy id');
    }
    const api = new DayinsureAPIv1(OpenAPI);

    return api.motorQuote.getApiV1MotorQuoteDocuments(id);
  };

  return useQuery<Array<DocumentDto>, ApiError>(query.documents, getDocumentsLinks, {
    keepPreviousData: true,
    onError: err => {
      if (err.status !== 404) {
        setGlobalError(err);
      }
    },
  });
};
