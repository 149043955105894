import { Form, useFormikContext } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Icon } from '@dayinsure/components';
import { useState } from 'react';
import clsx from 'clsx';
import { QuoteJourneyLayout, RemoveDriverModal } from '../../../components';
import { DriverFormData, QuoteJourneyFormData, YesNoAnswer } from '../../../types';
import { DriverList } from './DriverList';
import { MainDriverSelect } from './MainDriverSelect';
import { isYes, NEW_DRIVER_ID } from '../../../helpers';

const testId = 'drivers';

export const Drivers = () => {
  const [driverIdToremove, setDriverIdToremove] = useState<string | null>(null);
  const [isRemoveDriverModalOpen, setIsRemoveDriverModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const {
    values: { drivers },
    setFieldTouched,
    setFieldValue,
  } = useFormikContext<QuoteJourneyFormData>();
  const handleAddDriver = () => {
    navigate(`${NEW_DRIVER_ID}/details`);
  };

  const handleOpenRemoveDriverModal = (driverId: string) => () => {
    setDriverIdToremove(driverId);
    setIsRemoveDriverModalOpen(true);
  };

  const handleCloseRemoveDriverModal = () => {
    setDriverIdToremove(null);
    setIsRemoveDriverModalOpen(false);
  };

  const handleRemoveDriver = () => {
    if (!driverIdToremove) {
      return;
    }

    const filteredDrivers = drivers.filter(({ id }) => id !== driverIdToremove);
    const driverToFilterOut = drivers.find(({ id }) => id === driverIdToremove);

    if (isYes(driverToFilterOut?.isMainDriver?.id)) {
      setFieldTouched('mainDriver', false);
      setFieldValue('mainDriver', null);
    }

    if (filteredDrivers.length === 1) {
      const updatedDrivers = filteredDrivers.map((driver): DriverFormData => {
        const isMainDriver = isYes(driver.isProposer?.id)
          ? { id: YesNoAnswer.YES, name: 'Yes' }
          : { id: YesNoAnswer.NO, name: 'No' };
        return {
          ...driver,
          isMainDriver,
        };
      });
      setFieldValue('drivers', updatedDrivers);
    } else {
      setFieldValue('drivers', filteredDrivers);
    }
    handleCloseRemoveDriverModal();
  };

  const displayMainDriverSelect = drivers.length > 1;
  const isAddDriverButtonHidden = drivers.length >= 5;

  return (
    <QuoteJourneyLayout>
      <h1
        className="mb-8 text-xl text-center md:text-left"
        data-testid={`${testId}_title`}
      >
        Drivers
      </h1>
      <Card
        testId={`${testId}_info_card`}
        paddingLevel="small"
        classNames="mb-8 lg:mb-12"
      >
        <span>Please check the driver details below.</span>
      </Card>
      <DriverList testId={testId} onRemoveDriver={handleOpenRemoveDriverModal} />
      {!isAddDriverButtonHidden && (
        <Button
          id={`${testId}_add-driver_button`}
          testId={`${testId}_add-driver_button`}
          text="Add another driver"
          variant="bordered"
          onClick={handleAddDriver}
        />
      )}

      {isAddDriverButtonHidden && (
        <Card
          classNames={clsx('flex items-center', 'mb-8', 'mt-6', 'lg:mt-8')}
          paddingLevel="small"
          testId={`${testId}_max-drivers_message`}
        >
          <Icon name="warning" size="2rem" className="text-popup-content-1" />
          <p className="ml-6 text-main-content-1">
            You can’t add any more drivers. You have reached the maximum number of drivers
            that can be added to a policy.
          </p>
        </Card>
      )}

      <div className="mt-8 mb-16 lg:mt-12">
        <Form>
          {displayMainDriverSelect && <MainDriverSelect testId={testId} />}
          <Button
            id={`${testId}_continue-button`}
            testId={`${testId}_continue-button`}
            icon={{ name: 'arrow-right', position: 'right' }}
            text="Continue"
            fullWidth
            submit
          />
          <RemoveDriverModal
            isOpen={isRemoveDriverModalOpen}
            testId={testId}
            onClose={handleCloseRemoveDriverModal}
            onConfirm={handleRemoveDriver}
          />
        </Form>
      </div>
    </QuoteJourneyLayout>
  );
};
