import { AccountDetailsFormData } from '../../types/accountForm';
import { MarketConsentType } from '../../api/v1';

export const accountDetailsInitialFormData: AccountDetailsFormData = {
  email: null,
  mainPhoneNumber: null,
  secondaryPhoneNumber: null,
  marketingPreferences: [
    { id: MarketConsentType.EMAIL, name: 'Email', checked: false },
    { id: MarketConsentType.SMS, name: 'SMS', checked: false },
    { id: MarketConsentType.TELEPHONE, name: 'Telephone', checked: false },
    { id: MarketConsentType.POST, name: 'Post', checked: false },
  ],
};
