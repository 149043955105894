import { useContext } from 'react';
import { GlobalErrorContext, useNavigateWithReferrer } from '@dayinsure/shared';
import { useParams } from 'react-router-dom';
import { FormikHelpers } from 'formik';
import { QuoteJourneyFormData } from '../../../types';
import { QuoteJourneyRoutes } from '../../../routes';
import { getConvertGuestToCustomerPayload, getQuoteId } from '../../../helpers';
import { QuoteJourneyStatusContext } from '../../../contexts';
import { useReferrer } from '../../useReferrer';
import { useV1PutConvertGuestToCustomerMutation } from '../../mutations/useV1PutConvertGuestToCustomerMutation';
import { usePerson } from '../../usePerson';
import { useMarketingConsentCreate, useUpdateQuoteMutation } from '../../mutations';

const errorDialogCopy = {
  title: 'Sorry, there was a problem',
  message: 'There was a problem creating your account.',
  cta: 'Go back and try again',
  ctaId: 'create-account_sign-up-error-dialog_close-button',
};

export const useReviewSubmit = () => {
  const params = useParams();
  const navigate = useNavigateWithReferrer();
  const referrer = useReferrer();
  const { personId } = usePerson();
  const convertGuestToCustomerMutation = useV1PutConvertGuestToCustomerMutation();
  const createUpdateMarketingConsent = useMarketingConsentCreate();
  const { setGlobalError, clearGlobalError } = useContext(GlobalErrorContext);
  const { setIsLoading, setCopy } = useContext(QuoteJourneyStatusContext);
  const { mutate } = useUpdateQuoteMutation();

  const getOnSuccessHandler =
    ({ setFieldValue, setTouched, setSubmitting }: FormikHelpers<QuoteJourneyFormData>) =>
    () => {
      setFieldValue('isAccountCreated', true);
      setFieldValue('wasEmailChangedAfterCreation', false);

      const quoteId = getQuoteId(params);
      if (quoteId) {
        setSubmitting(false);
        setTouched({}, false);
        navigate(`${quoteId}/${QuoteJourneyRoutes.Payment}`);
        setIsLoading(false);
      }
    };

  const handleSetError = (error: unknown) => {
    setGlobalError(error, {
      ...errorDialogCopy,
      onClose: clearGlobalError,
    });
    setIsLoading(false);
  };

  return (
    formData: QuoteJourneyFormData,
    formikHelpers: FormikHelpers<QuoteJourneyFormData>
  ) => {
    const quoteId = getQuoteId(params);
    const {
      proposer: {
        telephoneNumber: { mobile, landline },
      },
    } = formData;
    if (formData.reviewUpdate && quoteId) {
      mutate({
        id: quoteId,
        proposer: {
          telephoneNumber: {
            mobile: mobile || null,
            landline: landline || null,
          },
        },
      });
    }

    const onSuccessHandler = getOnSuccessHandler(formikHelpers);
    if (formData.isCreateAccountDisplayed && !formData.isAccountCreated) {
      setCopy({
        title: 'Creating an account',
        subtitle: 'Please wait while we create an account',
      });
      setIsLoading(true);

      if (personId) {
        const accountData = getConvertGuestToCustomerPayload(formData, referrer);

        convertGuestToCustomerMutation.mutate(
          { accountData, personId },
          {
            onSuccess: onSuccessHandler,
            onError: handleSetError,
          }
        );
      } else {
        setIsLoading(false);
        setGlobalError({
          message: 'Error creating account',
        });
      }
    } else {
      onSuccessHandler();
    }

    if (personId && formData.journeyStartPosition === QuoteJourneyRoutes.Pcw) {
      createUpdateMarketingConsent.mutate(formData.proposer.marketingPreferences);
    }
  };
};
