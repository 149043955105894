import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { GlobalErrorContext, useNavigateWithReferrer } from '@dayinsure/shared';
import {
  Dayinsure as DayinsureAPIv1,
  OpenAPI,
  ApiError,
  GetMotorQuoteResponseDto,
} from '../../../api/v1';
import { QuoteJourneyStatusContext } from '../../../contexts';
import { query } from '../queryKeys';

export const useGetQuoteQuery = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigateWithReferrer();
  const { setGlobalError, clearGlobalError } = useContext(GlobalErrorContext);
  const { setIsLoading } = useContext(QuoteJourneyStatusContext);

  const getQuoteQuery = (quoteId: string) => {
    const api = new DayinsureAPIv1(OpenAPI);
    return api.motorQuote.getApiV1MotorQuote(quoteId);
  };

  return useMutation(getQuoteQuery, {
    onSuccess: data => {
      const formIsDeclined = !!(data?.declinatures && data?.declinatures?.length > 0);
      if (formIsDeclined) {
        setIsLoading(true);
        setGlobalError(null, {
          title: 'Sorry, we can’t give you a quote',
          message: `We’ve looked at the details you’ve given us, and unfortunately, we can’t give you a quote at this time.`,
          cta: 'Go back and try again',
          ctaId: 'quote-decline_cta',
          onClose: () => {
            navigate(-1);
            clearGlobalError();
            setIsLoading(false);
          },
        });
      } else {
        queryClient.setQueryData<GetMotorQuoteResponseDto>(
          query.quoteCreate(data.id || ''),
          quote => {
            return {
              ...quote,
              ...data,
            };
          }
        );
      }
    },
    onError: (err: ApiError) => {
      // Don't show a global error on 401. We want to handle this manually.
      if (!(err?.status === 401)) {
        setGlobalError(err);
      }
    },
  });
};
