import {
  FieldError,
  Label,
  DateField,
  DateFieldProps,
  IconNames,
} from '@dayinsure/components';
import { useField } from 'formik';
import { ReactNode, useRef } from 'react';
import { DateFieldValue } from '@dayinsure/components/src/components/Inputs/DateField/DateField';
import { useFocusOnError } from '../../../hooks';
import { pronunToNumber } from '../../../helpers';

export type FormDateFieldProps = {
  label: {
    icon: IconNames;
    text: ReactNode;
  };
} & Omit<DateFieldProps, 'value' | 'onChange' | 'onBlur' | 'error'>;

export const FormDateField = ({
  label,
  testId,
  ...dateFieldProps
}: FormDateFieldProps) => {
  const fieldRef = useRef<HTMLInputElement>(null);
  const controlRef = useRef<HTMLDivElement | null>(null);
  const [field, meta] = useField<DateFieldProps['value']>(dateFieldProps);
  useFocusOnError({ fieldRef: controlRef, name: dateFieldProps.name });

  const isErrorString = typeof meta.error === 'string';
  const errors = meta.error as {
    day?: string;
    month?: string;
    year?: string;
    parsedDate?: string;
  };
  const error = isErrorString
    ? meta.error
    : errors?.day || errors?.month || errors?.year || errors?.parsedDate;

  const onBlur = () => {
    const monthNum = pronunToNumber(field.value.month);
    const dayNum = pronunToNumber(field.value.day);
    const newFieldValue = { ...field.value, month: monthNum, day: dayNum };
    field.onBlur({ target: { name: field.name } });
    field.onChange({ target: { value: newFieldValue, name: field.name } });
  };

  const onChange = (value: DateFieldValue) => {
    field.onChange({ target: { value, name: field.name } });
  };

  return (
    <div className="relative form-field-spacing" ref={controlRef}>
      <Label
        name={label.text}
        icon={label.icon}
        htmlFor={dateFieldProps.id}
        disabled={dateFieldProps.disabled}
        className="mb-4"
        testId={`${testId}_label`}
      />
      <DateField
        {...dateFieldProps}
        {...field}
        testId={testId}
        value={field.value}
        onChange={onChange}
        onBlur={onBlur}
        error={meta.touched && meta.error !== undefined}
        ref={fieldRef}
      />
      {meta.touched && meta.error && (
        <FieldError
          testId={`${testId}_error`}
          id={`${dateFieldProps.id}_error`}
          error={error || ''}
          className="absolute right-0 mt-2"
        />
      )}
    </div>
  );
};
