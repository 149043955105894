import { Button, ContentContainer, PageContainer } from '@dayinsure/components';
import { useParams } from 'react-router-dom';
import { useNavigateWithReferrer } from '@dayinsure/shared';
import { usePolicyQuery } from '../../../hooks';
import { PolicyPaymentItem } from './PolicyPaymentItem/PolicyPaymentItem';
import { Loader } from '../../../components';
import { transactionAscendingOrderByDate } from './PolicyInfoPayments.utils';

const testId = 'policy-payments';

export const PolicyPayments = () => {
  const { id } = useParams<{ id: string }>();
  const { data: policy, isLoading } = usePolicyQuery(id);
  const navigate = useNavigateWithReferrer();

  if (isLoading) {
    return <Loader />;
  }

  const handleGoBack = () => navigate(`/policies/${id}`);

  return (
    <PageContainer className="font-raleway">
      <h1
        className="my-8 text-xl text-center lg:my-12 text-main-content-1"
        data-testid={`${testId}_title`}
      >
        Your payments
      </h1>
      <ContentContainer>
        {policy?.transactions?.sort(transactionAscendingOrderByDate).map(transaction => (
          <PolicyPaymentItem
            key={`${transaction.type}-${transaction.price?.total?.amount}`}
            transaction={transaction}
            testId={testId}
          />
        ))}
        <Button
          classNames="mt-10 mb-16"
          id={`${testId}_edit-button`}
          testId={`${testId}_edit-button`}
          submit
          fullWidth
          text="Back to policy"
          variant="secondary"
          onClick={handleGoBack}
        />
      </ContentContainer>
    </PageContainer>
  );
};
