import React, { useMemo, useState } from 'react';

export const PaymentContext = React.createContext<{
  checkoutContext: any | null;
  setCheckoutContext: any;
  orderId: string | null;
  setOrderId: any;
  cardFormVisible: boolean | null;
  setCardFormVisible: any;
  policyDelayed: boolean | null;
  setPolicyDelayed: any;
}>({
  checkoutContext: null,
  setCheckoutContext: () => {},
  orderId: null,
  setOrderId: () => {},
  cardFormVisible: true,
  setCardFormVisible: () => {},
  policyDelayed: false,
  setPolicyDelayed: () => {},
});

export const PaymentProvider = ({ children }: { children: React.ReactNode }) => {
  const [checkoutContext, setCheckoutContext] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [cardFormVisible, setCardFormVisible] = useState(true);
  const [policyDelayed, setPolicyDelayed] = useState(false);
  const context = useMemo(
    () => ({
      checkoutContext,
      setCheckoutContext,
      orderId,
      setOrderId,
      cardFormVisible,
      setCardFormVisible,
      policyDelayed,
      setPolicyDelayed,
    }),
    [cardFormVisible, checkoutContext, orderId, policyDelayed]
  );
  return <PaymentContext.Provider value={context}>{children}</PaymentContext.Provider>;
};
