import { MotorPolicyTransactionDto } from '../../../api/v1';

export const transactionAscendingOrderByDate = (
  a: MotorPolicyTransactionDto,
  b: MotorPolicyTransactionDto
) => {
  if (!a.createdDateTimeUtc || !b.createdDateTimeUtc) {
    return 0;
  }

  return (
    new Date(b.createdDateTimeUtc).getTime() - new Date(a.createdDateTimeUtc).getTime()
  );
};
