import { Card, Icon } from '@dayinsure/components';

export const ContactCard = () => (
  <section>
    <Card classNames="flex items-center mt-12 mb-8" paddingLevel="small">
      <Icon className="text-main-content-1" size="2rem" name="chat" />
      <div className="ml-6 text-main-content-1">
        If you need to make any other changes to your policy then you will need to message
        us on live chat.
      </div>
    </Card>
  </section>
);
