import { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { DirectDebitDetailsCard } from './DirectDebitDetailsCard';
import { DirectDebitDetailsForm } from './DirectDebitDetailsForm';
import { QuoteJourneyFormData } from '../../../../types';

type DirectDebitDetailsProps = {
  testId: string;
};

export const DirectDebitDetails = ({ testId }: DirectDebitDetailsProps) => {
  const {
    values: {
      isDirectDebitConfirmed,
      paymentDay,
      accountHolder,
      bankAccountNumber,
      sortCode,
    },
    setFieldValue,
  } = useFormikContext<QuoteJourneyFormData>();
  const [isEditing, setIsEditing] = useState<boolean>(true);
  const handleEditCard = () => {
    setIsEditing(true);
    setFieldValue('isDirectDebitConfirmed', false);
    setFieldValue('encryptedCardNumber', '');
    setFieldValue('cardholderName', '');
    setFieldValue('encryptedExpiryMonth', '');
    setFieldValue('encryptedExpiryYear', '');
    setFieldValue('encryptedSecurityCode', '');
    setFieldValue('isAnnualImportantInformationConfirmed', false);
    setFieldValue('isMonthlyImportantInformationConfirmed', false);
  };

  useEffect(() => {
    if (isDirectDebitConfirmed) {
      setIsEditing(false);
    }
  }, [isDirectDebitConfirmed]);

  return (
    <section className="mb-6 md:mb-12" data-testid={`${testId}_direct-debit-details`}>
      {isEditing ? (
        <DirectDebitDetailsForm testId={`${testId}_direct-debit-details-form`} />
      ) : (
        <DirectDebitDetailsCard
          paymentDate={paymentDay}
          accountHolder={accountHolder}
          accountNumber={bankAccountNumber}
          sortCode={sortCode}
          onEdit={handleEditCard}
          testId={`${testId}_direct-debit-details-card`}
        />
      )}
    </section>
  );
};
