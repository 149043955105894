import { AddOnModelDto } from '../../../../api/v1';
import { AddOn, YesNoDataEntry } from '../../../../types';
import { AddOnType } from './AddOns.types';
import { AddOnCheckboxOption } from './AddOnCheckboxSelect/types';

export const BREAKDOWN_NOT_NEEDED_ID = 'BREAKDOWN_NOT_NEEDED';

export const addOnToOption =
  (paymentFrequencyCodeId?: string) => (addOn: AddOnModelDto) => ({
    id: addOn.type?.code || '',
    identifier: addOn.type?.code?.toLowerCase() || '',
    name: addOn.type?.description || '',
    checked: addOn.selected || false,
    price: addOn.price?.total?.amount,
    pricePrefix:
      typeof addOn.price?.total?.amount === 'number' && addOn.price?.total?.amount > 0
        ? '+ '
        : '',
    priceSufix: paymentFrequencyCodeId === 'ANN' ? ' /year' : ' /month',
    priceMonthly: addOn.price?.monthly?.amount,
    selectedPlan: paymentFrequencyCodeId,
  });

export const isBreakdownAddOn = <T extends { id?: string }>(addon: T | null) =>
  addon?.id?.includes('BREAKDOWN') && addon?.id !== BREAKDOWN_NOT_NEEDED_ID;

export const isOptionalAddOn = <T extends { id?: string }>(addon: T | null) =>
  !addon?.id?.includes('BREAKDOWN') || addon?.id === BREAKDOWN_NOT_NEEDED_ID;

export const findAddOnByType = (
  addOns: AddOnModelDto[],
  addOnType: AddOnType | typeof BREAKDOWN_NOT_NEEDED_ID
): AddOnModelDto | undefined => addOns.find(addOn => addOn.type?.code === addOnType);

export const findBreakdownOptionalAddOnValue = (
  optionalAddOns: YesNoDataEntry[] | null[]
): YesNoDataEntry | null => {
  if (!optionalAddOns) {
    return null;
  }

  const lastAddOnOption = optionalAddOns?.at(-1);
  if (!lastAddOnOption) {
    return null;
  }

  return lastAddOnOption;
};

export const getBreakdownOptionalAddon = (
  breakdownOneAddon: AddOnModelDto,
  paymentFrequencyCodeId?: string,
  checked = false
): AddOnCheckboxOption => ({
  ...addOnToOption(paymentFrequencyCodeId)(breakdownOneAddon),
  id: BREAKDOWN_NOT_NEEDED_ID,
  name: 'RAC Breakdown Cover',
  checked,
  isOptionalBreakdownAddOn: true,
});

export const addBreakdownOptionalAddonOption =
  (breakdownOneAddon?: AddOnModelDto, paymentFrequencyCodeId?: string) =>
  (options: AddOn[]) => {
    if (options.length === 0 || !breakdownOneAddon) {
      return options;
    }

    const optionalBreakdownAddOnChecked = options
      .filter(isBreakdownAddOn)
      .some(addOn => addOn.checked);
    return [
      ...options,
      getBreakdownOptionalAddon(
        breakdownOneAddon,
        paymentFrequencyCodeId,
        optionalBreakdownAddOnChecked
      ),
    ];
  };
