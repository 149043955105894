import { ReactNode, createContext } from 'react';

type QuoteJourneyStatusCopy = {
  title: string;
  subtitle: string;
};

export type QuoteJourneyStatusContextType = {
  isLoadingState: boolean;
  showLoader: boolean;
  setIsLoadingState: (isLoading: boolean) => void;
  setIsLoading: (isLoading: boolean, duration?: number) => void;
  setIsError: (isError: boolean) => void;
  copy: QuoteJourneyStatusCopy;
  setCopy: (copy: QuoteJourneyStatusCopy) => void;
  resetCopy: () => void;
};

export type QuoteJourneyStatusContextWrapperProps = {
  children: ReactNode;
};

export const QuoteJourneyStatusContext = createContext<QuoteJourneyStatusContextType>({
  isLoadingState: false,
  showLoader: false,
  setIsLoadingState: () => {},
  setIsLoading: () => {},
  setIsError: () => {},
  copy: {
    title: '',
    subtitle: '',
  },
  setCopy: () => {},
  resetCopy: () => {},
});
