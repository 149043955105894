import { Card } from '@dayinsure/components';
import { VehicleDto } from '../../../../../api/v1';
import { displayTrackingDeviceInfo } from '../../../../../helpers';

type CarSpecsCardProps = {
  testId: string;
  vehicle: VehicleDto;
};

export const CarSpecsCard = ({ testId, vehicle }: CarSpecsCardProps) => {
  const alarmImmobiliser =
    vehicle.alarmImmobiliser?.type?.description || vehicle.alarmImmobiliser?.type?.code;

  return (
    <Card testId={`${testId}_car-spec-card`} classNames="w-full mb-6">
      <h3 className="font-raleway text-lg font-normal" data-testid={`${testId}_title`}>
        Car specs
      </h3>
      <Card.CardListItem
        label="Steering wheel side"
        icon="steering-wheel"
        testId={`${testId}_steerling-wheel-side-item`}
      >
        {vehicle.steeringWheelSide?.description || vehicle.steeringWheelSide?.code}
      </Card.CardListItem>
      <Card.CardListItem
        label="Number of seats"
        icon="seat"
        testId={`${testId}_seats-item`}
      >
        {vehicle.numberOfSeats}
      </Card.CardListItem>
      <Card.CardListItem
        label="Tracking device"
        icon="location"
        testId={`${testId}_tracking-device-item`}
      >
        {displayTrackingDeviceInfo(vehicle.trackingDevice)}
      </Card.CardListItem>
      {alarmImmobiliser && (
        <Card.CardListItem
          label="Alarm / immobiliser"
          icon="alarm"
          testId={`${testId}_alarm-immobiliser-item`}
        >
          {alarmImmobiliser}
        </Card.CardListItem>
      )}
      <Card.CardListItem
        label="Imported vehicle"
        icon="container"
        testId={`${testId}_imported-vehicle-item`}
      >
        {vehicle.isImported ? 'Yes' : 'No'}
      </Card.CardListItem>
    </Card>
  );
};
