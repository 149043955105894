import { Card } from '@dayinsure/components';
import clsx from 'clsx';
import { FeesAndChargesRow as Row } from './FeesAndChargesRow';
import { CostDto, FeesForDisplayDto } from '../../../../api/v1';

type FeesAndChargesProps = {
  testId: string;
  feesForDisplay?: FeesForDisplayDto;
  className?: string;
  missedPaymentFee?: CostDto;
  cancellationFee?: CostDto;
  administrationFee?: CostDto;
  isMta?: boolean;
  isUpcomingChange?: boolean;
};

export const FeesAndCharges = ({
  testId,
  className,
  feesForDisplay,
  missedPaymentFee,
  cancellationFee,
  administrationFee,
  isMta = false,
  isUpcomingChange = false,
}: FeesAndChargesProps) => {
  const amendmentFee = isMta ? administrationFee : feesForDisplay?.mtaFee;

  return (
    <Card
      classNames={clsx('mt-6 lg:mt-8 bg-content-background-prominent', className)}
      testId={`${testId}_fees-and-charges_card`}
    >
      <h3 className="mb-6 font-raleway text-lg">Other fees and charges</h3>
      {feesForDisplay?.cancellationFeeBeforeAndDuringCoolingOff && (
        <Row
          text="Cancellation within 14 days of buying or renewing your policy"
          value={Number(feesForDisplay?.cancellationFeeBeforeAndDuringCoolingOff.amount)}
        />
      )}
      {feesForDisplay?.cancellationFeeAfterCoolingOff && (
        <Row
          text="Cancellation after 14 days of buying or renewing your policy"
          value={Number(feesForDisplay?.cancellationFeeAfterCoolingOff?.amount)}
        />
      )}
      {administrationFee && !isMta && (
        <Row
          text="Set-up fee (non-refundable)"
          value={Number(administrationFee.amount)}
        />
      )}
      {amendmentFee && (
        <Row text="Amendment fee (non-refundable)" value={Number(amendmentFee?.amount)} />
      )}
      {!isUpcomingChange && (
        <>
          {!feesForDisplay?.missedPaymentFee && missedPaymentFee?.amount && (
            <Row text="Missed payment fee" value={Number(missedPaymentFee?.amount)} />
          )}
          {!feesForDisplay?.cancellationFee && cancellationFee?.amount && (
            <Row
              text="Finance cancellation fee"
              value={Number(cancellationFee?.amount)}
            />
          )}
          {feesForDisplay?.missedPaymentFee && (
            <Row
              text="Missed payment fee"
              value={Number(feesForDisplay?.missedPaymentFee?.amount)}
            />
          )}
          {feesForDisplay?.cancellationFee && (
            <Row
              text="Finance cancellation fee"
              value={Number(feesForDisplay?.cancellationFee?.amount)}
            />
          )}
        </>
      )}
    </Card>
  );
};
