import { everydayinsureLogo } from '@dayinsure/core';
import Referrer from '../types/referrer';

const everydayinsure: Referrer = {
  name: 'Everyday',
  theme: 'Everyday',
  logo: everydayinsureLogo,
  logoSize: 'regular',
  pageMeta: {
    title: 'Get A Quote | Car Insurance Quotes Online | Annual Cover',
    description: '',
    faviconUrl: '/favicon.ico',
  },
  customerAccessEnabled: true,
  identityProviderName: 'everydayinsureIdSrv',
  identityProviderDisplayName: 'Dayinsure',
  defaultCancelUrl: '/quote',
  marketingConsent: {
    disclaimerText:
      "Fancy receiving discounts, rewards, interesting news or special deals? Select your preferred contact method(s) and we'll send you occasional information that we hope you'll love. We'll also include the best offers from our friendly partners and the wider insurance group we belong to.",
    options: [
      { id: 'EMAIL', name: 'Email' },
      { id: 'TEXT', name: 'Text' },
      { id: 'TELEPHONE', name: 'Telephone' },
      { id: 'POST', name: 'Post' },
    ],
  },
  homeUrl: '/quote',
  showAppLinks: true,
  footerDomain: {
    displayName: 'everydayinsure.com',
    href: '#', // TODO: change when it's known
  },
  footerLegalWording:
    'Everydayinsure™ is a registered trademark © {{year}} Everydayinsure.com Ltd. Authorised and regulated by the Financial Conduct Authority No: 304010. Company Registration No: 04996289',
  useWebsiteDocuments: true,
};

export const referrers: { [key: string]: Referrer } = {
  everydayinsure,
};
