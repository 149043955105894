import { Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { NavigateWithReferrer } from '@dayinsure/shared';
import {
  AccountRoutes,
  AuthRoutes,
  DashboardRoutes,
  PoliciesRoutes,
} from './preloadContextValue';
import { QuoteRoutes } from './QuoteRoutes';
import { Help, Redirect, Retail } from '../pages';
import { Loader } from '../components';
import { AuthPaths } from './routes';
import { AccountProtectedRoute } from '../components/Auth/AccountProtectedRoute/AccountProtectedRoute';

export const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<Navigate to={{ pathname: '/quote' }} replace />} />
    <Route
      path="/account/*"
      element={
        <Suspense fallback={<Loader />}>
          <AccountRoutes />
        </Suspense>
      }
    />
    <Route
      path="/auth/*"
      element={
        <Suspense fallback={<Loader />}>
          <AuthRoutes />
        </Suspense>
      }
    />
    <Route
      path="/dashboard/*"
      element={
        <AccountProtectedRoute>
          <Suspense fallback={<Loader />}>
            <DashboardRoutes />
          </Suspense>
        </AccountProtectedRoute>
      }
    />
    <Route
      path="/policies/*"
      element={
        <Suspense fallback={<Loader />}>
          <PoliciesRoutes />
        </Suspense>
      }
    />
    <Route path="/retail" element={<Retail />} />
    <Route path="/quote/*" element={<QuoteRoutes />} />
    <Route path="/login/redirect" element={<Redirect />} />
    <Route path="/help" element={<Help />} />
    <Route
      path={AuthPaths.ForgottenPassword}
      element={
        <NavigateWithReferrer replace to={`/auth/${AuthPaths.ForgottenPassword}`} />
      }
    />
    <Route
      path={AuthPaths.SignUp}
      element={<NavigateWithReferrer replace to={`/auth/${AuthPaths.SignUp}`} />}
    />
  </Routes>
);
