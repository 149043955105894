import { PendingChangesDto, PolicyTransactionType } from '../../../../api/v1';
import { PolicyPendingChangesMessage } from '../PolicyInfoPendingChanges';
import { CancellationPendingPanel } from './CancellationPendingPanel';

type PolicyInfoPanelsProps = {
  pendingChanges?: PendingChangesDto;
  testId?: string;
};
export const PolicyInfoPanels = ({ pendingChanges, testId }: PolicyInfoPanelsProps) => {
  if (!pendingChanges) {
    return null;
  }
  const { effectiveDateTimeUtc, transactionType } = pendingChanges;
  const cancellation = transactionType === PolicyTransactionType.CANCELLATION;

  return (
    <div>
      {effectiveDateTimeUtc && (
        <>
          {!cancellation ? (
            <PolicyPendingChangesMessage
              testId={testId}
              changesStartDateUtc={effectiveDateTimeUtc}
              policyVersion={pendingChanges?.policyVersion}
            />
          ) : (
            <CancellationPendingPanel
              testId={testId}
              cancelledDateTimeUtc={effectiveDateTimeUtc}
            />
          )}
        </>
      )}
    </div>
  );
};
