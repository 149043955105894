import {
  Button,
  ContentContainer,
  ListboxOption,
  PageContainer,
} from '@dayinsure/components';
import { useNavigateWithReferrer } from '@dayinsure/shared';
import { useParams } from 'react-router-dom';
import { useEffect, useState, Fragment } from 'react';
import { DriverDetailsCard, DriverHomeCard, DriverLicenceCard } from './DriverCards';
import { DriversSelector } from './DriversSelector/DriversSelector';
import { PolicyDriverDto } from '../../../../api/v1';

import { usePolicyQuery } from '../../../../hooks';
import { DriverCardsProps } from './DriverCards/DriverCards.types';

const testId = 'policy-drivers';

const toDriverOptions = (drivers: PolicyDriverDto[]): ListboxOption[] =>
  drivers.map((driver: PolicyDriverDto, index) => {
    const mainDriver = driver.isMainDriver ? ' [main driver]' : '';
    return {
      id: index.toString(),
      name: `${driver.firstName} ${driver.lastName}${mainDriver}`,
    };
  });

export const Drivers = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigateWithReferrer();
  const [selectedDriver, setSelectedDriver] = useState<ListboxOption>();
  const [policyDrivers, setPolicyDrivers] = useState<DriverCardsProps['driver'][]>([]);
  const [driverOptions, setDriverOptions] = useState<ListboxOption[]>([]);
  const { data: policy } = usePolicyQuery(id);

  useEffect(() => {
    if (policy?.drivers?.length) {
      setPolicyDrivers(
        policy.drivers.map((driver, index) => ({
          ...driver,
          id: index.toString(),
        }))
      );
    }

    const options = toDriverOptions(policy?.drivers || []);
    setDriverOptions(options);
    setSelectedDriver(options?.[0]);
  }, [policy]);

  const handleNavigate = () => {
    navigate(`/policies/${id}`);
  };

  const selectedDriverData = policyDrivers.find(
    singleDriver => singleDriver?.id === selectedDriver?.id
  );

  return (
    <PageContainer testId={testId}>
      <ContentContainer>
        <div className="flex flex-col items-center mt-8 mb-16 w-full sm:mt-12 md:mb-24 text-main-content-1">
          <h1
            className="self-center mb-8 text-xl sm:mb-12"
            data-testid={`${testId}_title`}
          >
            Drivers
          </h1>

          {driverOptions?.length > 1 && selectedDriver && (
            <DriversSelector
              testId={testId}
              value={selectedDriver}
              options={driverOptions}
              onChange={setSelectedDriver}
            />
          )}
          {selectedDriverData ? (
            <Fragment key={selectedDriverData.id}>
              <DriverDetailsCard testId={testId} driver={selectedDriverData} />
              {selectedDriverData?.isPolicyholder && (
                <DriverHomeCard
                  testId={testId}
                  driver={policy?.policyholder as PolicyDriverDto}
                />
              )}
              <DriverLicenceCard testId={testId} driver={selectedDriverData} />
            </Fragment>
          ) : null}
          <Button
            variant="secondary"
            classNames="w-full mt-6 md:mt-10"
            id={`${testId}_back-to-policy-button`}
            data-testid={`${testId}_back-to-policy-button`}
            text="Back to policy"
            onClick={handleNavigate}
          />
        </div>
      </ContentContainer>
    </PageContainer>
  );
};
