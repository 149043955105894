import { FeeDto } from '../../api/v1';

export const findAdministrationFee = (fees: FeeDto[] | null = []): FeeDto | undefined => {
  if (!fees) {
    return undefined;
  }
  const administrationFee = fees.find(
    fee => fee.type?.toLowerCase() === 'AdministrationFee'.toLowerCase()
  );
  const mtaFee = fees.find(fee => fee.type?.toLowerCase() === 'MTAFee'.toLowerCase());

  return administrationFee || mtaFee;
};
