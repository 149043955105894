import { EndorsementDto } from '../../../api/v1';

export const hasEndorsement205 = (endorsments: EndorsementDto[]): boolean => {
  return endorsments.some(endorsment => endorsment.code === '205');
};

export const hasEndorsement202Or208 = (endorsments: EndorsementDto[]): boolean => {
  return endorsments.some(
    endorsment => endorsment.code === '202' || endorsment.code === '208'
  );
};
