import { Card, Icon, PageContainer } from '@dayinsure/components';
import { LinkWithReferrer } from '@dayinsure/shared';
import { SupportCard } from './SupportCard';
import { OpeningHoursCard } from './OpeningHoursCard';
import { briefcase } from '../../assets';
import { ContactCards } from '../../components';

const testId = 'help';

export const Help = () => {
  return (
    <PageContainer testId={`${testId}-page-container`}>
      <div className="mx-auto sm:min-w-tablet-container lg:min-w-desktop-container lg:max-w-desktop-container text-main-content-1">
        <div className="flex flex-col my-8 w-full sm:mt-12 md:mb-12 text-main-content-1">
          <h1
            className="self-center font-raleway text-xl text-center"
            data-testid={`${testId}-page-title`}
          >
            Help
          </h1>
        </div>

        <section className="flex justify-center">
          <LinkWithReferrer
            data-testid={`${testId}-link-card_manage-policies`}
            to="/policies"
          >
            <Card paddingLevel="small" classNames="h-48 !p-8 mb-16">
              <img src={briefcase} alt="trophy" className="mx-auto mb-5 h-[88px]" />
              <div className="flex justify-center items-center font-semibold">
                Manage policies
                <Icon name="arrow-right" size="0.75rem" className="ml-3" />
              </div>
            </Card>
          </LinkWithReferrer>
        </section>

        <SupportCard testId={`${testId}_support-card`} />
        <OpeningHoursCard testId={`${testId}_opening-hours-card`} />
        <ContactCards testId={testId} className="mb-12" displayRAC />
      </div>
    </PageContainer>
  );
};
