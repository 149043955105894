import { useQuery } from 'react-query';
import { useContext } from 'react';
import { GlobalErrorContext } from '@dayinsure/shared';
import {
  Dayinsure as DayinsureAPIv1,
  OpenAPI,
  ApiError,
  MarketingConsentsResponseDto,
} from '../../../api/v1';
import { query } from '../queryKeys';

export const useGetMarketingConsentQuery = (enabled: boolean) => {
  const { setGlobalError } = useContext(GlobalErrorContext);

  const getMarketingConsent = () => {
    const api = new DayinsureAPIv1(OpenAPI);
    return api.marketingConsent.getApiV1MarketingConsent();
  };

  return useQuery<MarketingConsentsResponseDto, ApiError>(
    query.marketingConsent,
    getMarketingConsent,
    {
      enabled,
      retry: (count, error: ApiError) =>
        error.status !== 401 && error.status !== 404 && count < 3,
      onError: (error): void => {
        if (error.status === 404) return;

        if (error.status !== 401) {
          setGlobalError(error, {
            message: 'Something doesn’t look quite right, please go back and try again.',
          });
        }
      },
    }
  );
};
