import { Button, Card, Dialog, Icon } from '@dayinsure/components';

type CancelChangesDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  testId: string;
};

export const CancelChangesDialog = ({
  isOpen,
  onClose,
  testId,
}: CancelChangesDialogProps) => {
  return (
    <Dialog open={isOpen} onClose={onClose} title="Cancel policy changes">
      <Card
        classNames="!bg-popup-background text-left font-railway text-popup-content-1 flex items-center gap-4 md:gap-6"
        paddingLevel="small"
      >
        <Icon name="warning" size="2rem" className="text-popup-content-1" />
        <p>
          If you need to cancel these upcoming policy changes, please contact us through
          live chat.
        </p>
      </Card>

      <Button
        id={`${testId}_dialog_back-button`}
        testId={`${testId}_dialog_back-button`}
        fullWidth
        onClick={onClose}
        text="Go back"
        classNames="mt-6 md:mt-8"
        variant="primary"
      />
    </Dialog>
  );
};
