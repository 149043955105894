import { Tooltip } from '@dayinsure/components';
import { useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { FormFieldBox } from '../../../../components';
import { FormPaymentTypeRadio } from './FormPaymentTypeRadio';
import { CostDto, InsurancePriceDto, PaymentPlanDto } from '../../../../api/v1';
import { getPaymentTypeOptions } from './PaymentType.utils';
import { QuoteJourneyFormData } from '../../../../types';
import { isMonthlyPaymentPlan } from '../../../../helpers';

type PaymentTypeProps = {
  disabled: boolean;
  paymentPlans: PaymentPlanDto[];
  insurencePrice?: InsurancePriceDto;
  testId: string;
  onChangeOptional?: (option: { id: string; name: string }) => void;
  title?: string;
  tooltipInfo?: string;
  totalPriceIncAddOns?: CostDto;
  showTooltip?: boolean;
  isMta?: boolean;
  isRefund?: boolean;
};

export const PaymentType = ({
  disabled,
  paymentPlans,
  insurencePrice,
  testId,
  onChangeOptional,
  title,
  tooltipInfo,
  totalPriceIncAddOns,
  showTooltip = true,
  isMta = false,
  isRefund = false,
}: PaymentTypeProps) => {
  const {
    values: { usualPaymentFrequency },
  } = useFormikContext<QuoteJourneyFormData>();
  const paymentTypeOptions = useMemo(
    () =>
      getPaymentTypeOptions(
        paymentPlans,
        testId,
        usualPaymentFrequency,
        insurencePrice,
        totalPriceIncAddOns,
        isMta,
        isRefund
      ) || [],
    [
      paymentPlans,
      testId,
      usualPaymentFrequency,
      insurencePrice,
      totalPriceIncAddOns,
      isMta,
      isRefund,
    ]
  );

  const selectPlanOpion = useMemo(() => {
    if (paymentPlans?.length > 1) {
      return {
        id: 'MON',
        name: 'Monthly',
      };
    }
    if (paymentPlans.length === 1) {
      const plan = paymentPlans[0];
      const isMonthlyPlan = isMonthlyPaymentPlan(plan?.type?.code);
      return isMonthlyPlan
        ? {
            id: 'MON',
            name: 'Monthly',
          }
        : {
            id: 'ANN',
            name: 'Annually',
          };
    }
    return {
      id: 'ANN',
      name: 'Annually',
    };
  }, [paymentPlans]);

  useEffect(() => {
    if (!usualPaymentFrequency?.code) {
      if (selectPlanOpion && onChangeOptional) {
        onChangeOptional(selectPlanOpion);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="my-6 md:my-12" data-testid={`${testId}_payment-type`}>
      <h3
        className="relative w-full font-raleway font-normal form-subtitle-compact"
        data-testid={`${testId}_payment-type_title`}
      >
        {title || 'How do you want to pay for your policy?'}
        {showTooltip && (
          <div className="inline-block ml-2 sm:!relative">
            <Tooltip
              ariaLabel="hint"
              className="inline-flex top-[72px] flex-col xs:top-8 text-popup-content-1"
              position="relative"
              testId={`${testId}_how-do-you-want-to-pay`}
              variant="annual"
            >
              {tooltipInfo || (
                <>
                  <h4 className="mb-2 font-bold">Paying for your policy</h4>
                  <p>
                    If you pay your insurance by monthly instalments, an interest charge
                    and finance arrangement fee may be added. These are included in the
                    monthly instalments quoted.
                  </p>
                </>
              )}
              <Tooltip.Close className="pt-2 font-bold text-left text-popup-link">
                Got it
              </Tooltip.Close>
            </Tooltip>
          </div>
        )}
      </h3>
      <FormFieldBox>
        <FormPaymentTypeRadio
          name="usualPaymentFrequency.code"
          options={paymentTypeOptions}
          id={`${testId}_payment-type-radio`}
          testId={`${testId}_payment-type-radio`}
          cols={{ mobile: 1, tablet: 1, desktop: 1 }}
          onChangeOptional={onChangeOptional}
          disabled={disabled}
          isMta={isMta}
        />
      </FormFieldBox>
    </div>
  );
};
