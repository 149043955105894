import { MotorPolicyPaymentPlanDto } from '../../../api/v1';
import { isMonthlyPaymentPlan } from '../../utils';

export const isRefund = (paymentPlan?: MotorPolicyPaymentPlanDto): boolean => {
  const isMonthly = isMonthlyPaymentPlan(paymentPlan?.paymentFrequency?.code);
  const costDifference = isMonthly
    ? paymentPlan?.installmentDetails?.financeAmount?.amount
    : paymentPlan?.installmentDetails?.total?.amount;

  return (costDifference || 0) < 0;
};
