import { Card, Icon } from '@dayinsure/components';
import clsx from 'clsx';
import { useNavigateWithReferrer } from '@dayinsure/shared';
import { QuoteJourneyRoutes } from '../../../../../../routes';

type UserEmailCardProps = {
  email: string;
  testId: string;
  className?: string;
  noEdit?: boolean;
};

export const UserEmailCard = ({
  className,
  email,
  testId,
  noEdit = false,
}: UserEmailCardProps) => {
  const navigate = useNavigateWithReferrer();

  const onEdit = () => navigate(`/quote/${QuoteJourneyRoutes.YourDetails}`);

  return (
    <Card
      paddingLevel="small"
      classNames={clsx('bg-content-background-prominent', className)}
      testId={`${testId}_user-email-card`}
    >
      <div data-testid={testId} className="flex justify-between items-start">
        <div className="flex grow justify-between items-center mb-2 space-between">
          <span
            data-testid={`${testId}_label`}
            className={clsx('flex gap-2 items-center text-sm leading-3')}
          >
            <Icon name="email" />
            Email address
          </span>
        </div>

        {!noEdit && (
          <button
            className="flex items-center text-sm font-semibold leading-[14px] text-link"
            data-testid={`${testId}_edit-email-button`}
            onClick={onEdit}
            type="button"
          >
            <Icon name="edit" className="mr-2" size="12px" />
            Edit
          </button>
        )}
      </div>

      <div
        data-testid={`${testId}_email`}
        className="min-h-[1.5rem] leading-6 break-words"
      >
        {email}
      </div>
    </Card>
  );
};
