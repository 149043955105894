import * as Yup from 'yup';
import { emailRegex, isValidLandLineNumber, isValidPhoneNumber } from '@dayinsure/shared';

export const accountDetailsValidationSchema = Yup.object().shape({
  email: Yup.string()
    .matches(emailRegex, {
      message: 'Please check this',
      excludeEmptyString: true,
    })
    .required('Required'),
  secondaryPhoneNumber: Yup.string()
    .nullable()
    .test('phone-num', 'Please check this', (value, ctx) => {
      const {
        parent: { mainPhoneNumber },
        createError,
      } = ctx;
      if (mainPhoneNumber && !value) {
        return true;
      }
      if (!value) {
        return createError({ message: 'Required' });
      }
      return isValidLandLineNumber(value);
    }),
  mainPhoneNumber: Yup.string()
    .nullable()
    .test('phone-num', 'Please check this', (value, ctx) => {
      const {
        parent: { secondaryPhoneNumber },
        createError,
      } = ctx;
      if (secondaryPhoneNumber && !value) {
        return true;
      }
      if (!value) {
        return createError({ message: 'Required' });
      }
      return isValidPhoneNumber(value);
    }),
});
