import { Card, Icon } from '@dayinsure/components';

type SupportCardProps = {
  testId: string;
};

export const SupportCard = ({ testId }: SupportCardProps) => {
  return (
    <Card classNames="flex items-center mb-8" paddingLevel="small" testId={testId}>
      <Icon className="text-main-content-1" size="2rem" name="live-chat" />
      <div className="ml-6 font-raleway text-main-content-1">
        You can contact our Customer Service team via live chat or by emailing{' '}
        <a
          className="font-semibold underline text-link"
          href="mailto:support@everydayinsure.co.uk"
        >
          support@everydayinsure.co.uk
        </a>
        .
      </div>
    </Card>
  );
};
