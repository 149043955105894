type RowProps = {
  label: string;
  value: string;
};

export const Row = ({ label, value }: RowProps) => (
  <div className="flex justify-between items-center w-full font-lato text-sm font-normal leading-4">
    <p>{label}</p>
    <p className="font-bold">{value}</p>
  </div>
);
