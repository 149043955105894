import { Button, Card, Dialog, Icon } from '@dayinsure/components';

type AutoRenewPolicyModalProps = {
  open: boolean;
  onClose: (turnOn?: boolean) => void;
  testId?: string;
};
export const AutoRenewPolicyModal = ({
  open,
  onClose,
  testId,
}: AutoRenewPolicyModalProps) => {
  const goBackAndTurnOn = () => {
    onClose(true);
  };

  const leaveOff = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={goBackAndTurnOn} title="Auto-renew policy">
      <Card
        classNames="!bg-popup-background text-left font-railway text-popup-content-1 flex items-center gap-4 md:gap-6"
        paddingLevel="small"
        testId={`${testId}_content`}
      >
        <Icon name="warning" size="2rem" className="text-popup-content-1" />
        <p>
          You are turning off auto-renew for this policy. If you don&apos;t contact us by
          the renewal date to make a payment, your insurance will end on the &apos;Cover
          end&apos; date displayed. Turning off auto-renew won’t automatically cancel any
          existing Direct Debit, while the policy is still active.
        </p>
      </Card>
      <Button
        id={`${testId}_back-button`}
        testId={`${testId}_back-button`}
        fullWidth
        onClick={goBackAndTurnOn}
        text="Go back and leave on auto-renew"
        classNames="mt-6 md:mt-8"
      />

      <Button
        id={`${testId}_turn-off-button`}
        testId={`${testId}_turn-off-button`}
        fullWidth
        onClick={leaveOff}
        text="Turn off auto-renew"
        classNames="mt-6 md:mt-8"
        variant="dialogSecondary"
      />
    </Dialog>
  );
};
