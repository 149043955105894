import { ContentContainer, PageContainer, Toast } from '@dayinsure/components';
import { Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { GlobalErrorContext } from '@dayinsure/shared';
import { TopCards } from '../../../components';
import { AccountNavButtons } from '../AccountNavButtons';
import { ContactDetails } from '../../Quote/YourDetails/ContactDetails';
import { QuoteLoader } from '../../Quote/QuoteJourney/QuoteLoader';
import { AccountDetailsFormData } from '../../../types/accountForm';
import {
  accountDetailsValidationSchema,
  accountDetailsInitialFormData,
} from '../../../forms';
import {
  useMarketingConsentCreate,
  usePerson,
  useV2PutPersonMutation,
} from '../../../hooks';
import { MarketingPreferences } from '../../Quote/YourDetails/MarketingPreferences';

const testId = 'account-details';

export const AccountDetails = () => {
  const [successToastState, setSuccessToastState] = useState(false);
  const { person } = usePerson();
  const { setGlobalError } = useContext(GlobalErrorContext);
  const updateAccountMutation = useV2PutPersonMutation();
  const updateMarketingConsent = useMarketingConsentCreate();
  const personInitialData = {
    email: person?.email,
    mainPhoneNumber: person?.mainPhoneNumber || null,
    secondaryPhoneNumber: person?.secondaryPhoneNumber || null,
  };
  const onError = (error: unknown) => {
    setGlobalError(error, {
      ctaId: 'account_details_cta',
    });
  };
  const openSuccessToast = () => {
    setSuccessToastState(true);
  };

  const closeSuccessToast = () => {
    setSuccessToastState(false);
  };
  const submitForm = (values: AccountDetailsFormData) => {
    const { email, mainPhoneNumber, secondaryPhoneNumber, marketingPreferences } = values;

    if (person?.id) {
      updateAccountMutation.mutate(
        {
          id: person.id,
          personData: {
            email,
            mainPhoneNumber: mainPhoneNumber || null,
            secondaryPhoneNumber: secondaryPhoneNumber || null,
          },
        },
        {
          onError,
          onSuccess: openSuccessToast,
        }
      );

      updateMarketingConsent.mutate(marketingPreferences);
    }
  };
  return (
    <>
      <QuoteLoader />
      {person && (
        <Formik<AccountDetailsFormData>
          initialValues={{ ...accountDetailsInitialFormData, ...personInitialData }}
          onSubmit={submitForm}
          validationSchema={accountDetailsValidationSchema}
          enableReinitialize
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <PageContainer className="font-raleway">
                <h1
                  className="my-8 text-xl text-center md:my-12 lg:my-16 text-main-content-1"
                  data-testid={`${testId}_form-title`}
                >
                  Account details
                </h1>

                <ContentContainer>
                  <TopCards
                    testId={`${testId}_cards`}
                    infoCardText="Edit your account details below."
                  />
                  <ContactDetails
                    testId={`${testId}_contact-details`}
                    emailDisabled={!!person?.email}
                    customFieldsAddress={{
                      email: 'email',
                      mobile: 'mainPhoneNumber',
                      landline: 'secondaryPhoneNumber',
                    }}
                    noHeader
                  />
                  <MarketingPreferences
                    formNameSpaceKey="marketingPreferences"
                    testId={`${testId}_marketing-preferences`}
                  />
                  <AccountNavButtons testId={testId} />
                  <Toast
                    id="account-details-toast"
                    message="Account details saved"
                    open={successToastState}
                    onClose={closeSuccessToast}
                  />
                </ContentContainer>
              </PageContainer>
            </form>
          )}
        </Formik>
      )}
    </>
  );
};
