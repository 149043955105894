import { ReactElement } from 'react';
import { createPortal } from 'react-dom';

type PortalProps = {
  children: ReactElement;
};

export const Portal = ({ children }: PortalProps) => {
  const modalTag = document?.getElementById('modal');
  if (!modalTag) {
    return null;
  }
  return createPortal(<div>{children}</div>, modalTag);
};
