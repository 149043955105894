import { Icon } from '@dayinsure/components';

type LegalExpensesProps = {
  testId: string;
};

export const LegalExpenses = ({ testId }: LegalExpensesProps) => {
  return (
    <div className="font-lato text-sm leading-5" data-testid={testId}>
      <h4>
        If you have had an accident that is not your fault, would you need to claim
        compensation?
      </h4>

      <ul className="pl-4 my-4 list-disc">
        <li>
          Covers legal costs up to £100,000 if you have an accident that isn’t your fault
        </li>
        <li>
          Claim for injury, death and expenses you’ve had to pay, due to a road traffic
          accident
        </li>
        <li>Cover provided by ARC Legal Assistance</li>
      </ul>

      <div>
        <div className="flex items-center mt-6">
          <Icon name="download" className="mr-2 text-link" />
          <a
            className="font-raleway text-base font-semibold underline text-link"
            href="/#"
            target="_blank"
            rel="noopener noreferrer"
          >
            Insurance product information document
          </a>
        </div>
        <div className="flex items-center mt-6">
          <Icon name="download" className="mr-2 text-link" />
          <a
            className="font-raleway text-base font-semibold underline text-link"
            href="/#"
            target="_blank"
            rel="noopener noreferrer"
          >
            Policy Wording
          </a>
        </div>
      </div>
    </div>
  );
};
