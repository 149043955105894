import Icon, { IconNames } from '../Icon/Icon';

type ListItemProps = {
  icon: IconNames;
  title: string;
  subTitle: string;
  removeIcon?: IconNames;
  removeText?: string;
  onRemove(): void;
};

export const ListItem = ({
  icon,
  title,
  subTitle,
  removeIcon = 'remove',
  removeText = 'Remove',
  onRemove,
}: ListItemProps) => {
  return (
    <div className="flex justify-between p-8 mt-8 w-full rounded-md bg-content-background">
      <div>
        <span className="flex items-center font-lato text-sm opacity-60">
          <Icon className="mr-2" name={icon} size="0.75rem" />
          {subTitle}
        </span>
        <p className="font-raleway" id="car-details-modification-item-name">
          {title}
        </p>
      </div>

      <button
        className="flex items-center self-start font-raleway text-sm font-semibold text-link"
        id="car-details-modification-item-remove-button"
        type="button"
        onClick={onRemove}
      >
        <Icon className="mr-2" name={removeIcon} />
        {removeText}
      </button>
    </div>
  );
};
