import { Card } from '@dayinsure/components';
import { useFormikContext, getIn } from 'formik';
import { useEffect } from 'react';
import { FormCheckboxSelect, Loader } from '../../../../components';
import { useGetMarketingConsentQuery, usePerson } from '../../../../hooks';
import { QuoteJourneyFormData } from '../../../../types';
import { mergeMarketingPreferences } from './MarketingPreferences.util';

type MarketingPreferencesProps = {
  formNameSpaceKey: string;
  testId: string;
};

export const MarketingPreferences = ({
  formNameSpaceKey,
  testId,
}: MarketingPreferencesProps) => {
  const { person } = usePerson();
  const { data, isLoading } = useGetMarketingConsentQuery(!!person);
  const { setFieldValue, values } = useFormikContext<QuoteJourneyFormData>();

  useEffect(() => {
    if (person && data?.marketingConsents) {
      setFieldValue(
        formNameSpaceKey,
        mergeMarketingPreferences(getIn(values, formNameSpaceKey), data.marketingConsents)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.marketingConsents, person, setFieldValue]);

  return (
    <div data-testid={testId} className="my-8 md:my-16">
      <h3
        className="mb-6 font-raleway text-lg font-normal md:mb-8"
        data-testid={`${testId}_title`}
      >
        Keeping you updated
      </h3>
      <Card
        testId={`${testId}_description`}
        classNames="mb-6 md:mb-8 pre-wrap"
        paddingLevel="small"
      >
        Fancy receiving discounts, rewards, interesting news or special deals? Select your
        preferred contact method(s) and we’ll send you occasional information that we hope
        you’ll love. We’ll also include the best offers from our friendly partners and the
        wider insurance group we belong to.
      </Card>
      {isLoading ? (
        <Loader />
      ) : (
        <FormCheckboxSelect
          id={`${testId}_checkbox-select`}
          testId={`${testId}_checkbox-select`}
          name={formNameSpaceKey}
          cols={{ mobile: 1, tablet: 2, desktop: 2 }}
        />
      )}
    </div>
  );
};
