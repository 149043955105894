import { useMutation } from 'react-query';
import {
  Dayinsure as DayInsureAPI,
  OpenAPI,
  PatchPolicyRequestDto,
} from '../../../api/v1';

export const usePatchMotorPolicy = () => {
  const patchMotorPolicy = ({
    id,
    payload,
  }: {
    id: string;
    payload: PatchPolicyRequestDto;
  }) => {
    const api = new DayInsureAPI(OpenAPI);

    return api.motorPolicy.patchApiV1MotorPolicy(id, payload);
  };

  return useMutation({
    mutationFn: patchMotorPolicy,
  });
};
