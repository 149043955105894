import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useFormikContext } from 'formik';
import defaultsDeep from 'lodash.defaultsdeep';
import { QuoteJourneyRoutes } from '../../../routes';
import { QuoteJourneyFormData } from '../../../types';
import { useQuoteFetchQuery } from '../../queries';
import { getQuoteId, parseQuoteResponseToFormikState } from '../../../helpers';

const afterPostQuoteRoutes = [
  QuoteJourneyRoutes.YourQuote,
  QuoteJourneyRoutes.Review,
  QuoteJourneyRoutes.Payment,
  QuoteJourneyRoutes.PolicyConditions,
  QuoteJourneyRoutes.Pcw,
];

const getRouteName = (path: string): QuoteJourneyRoutes => {
  const routeArr = path.split('/') || [];
  return routeArr.slice(-1)[0] as QuoteJourneyRoutes;
};
export const useQuoteNavigation = () => {
  const params = useParams();
  const quoteId = getQuoteId(params);
  const { values, setValues } = useFormikContext<QuoteJourneyFormData>();
  const [prevRoute, setPrevRoute] = useState<QuoteJourneyRoutes | null>(null);
  const location = useLocation();
  const fetchQuote = useQuoteFetchQuery();
  const parseQuoteToFormikState = async (
    policyCorrelationId: string | null | undefined,
    quoteDateTimeUtc: string | null | undefined
  ) => {
    if (quoteId) {
      const quote = await fetchQuote(quoteId);
      const parsedQuote = parseQuoteResponseToFormikState(
        quote,
        policyCorrelationId,
        quoteDateTimeUtc
      );
      // overrides current state with parsed response, missing values are filled out from current state
      const mergedValuesWithQuote: QuoteJourneyFormData = defaultsDeep(
        parsedQuote,
        values
      );
      if (parsedQuote) {
        setValues(mergedValuesWithQuote);
      }
    }
  };

  useEffect(() => {
    const routeName = getRouteName(location?.pathname);
    const coverPageCondition =
      prevRoute === QuoteJourneyRoutes.Cover &&
      routeName === QuoteJourneyRoutes.YourQuote;
    if (
      prevRoute &&
      !afterPostQuoteRoutes.includes(prevRoute) &&
      afterPostQuoteRoutes.includes(routeName) &&
      routeName &&
      !coverPageCondition
    ) {
      (async () => {
        await parseQuoteToFormikState(
          values.policyCorrelationId,
          values.quoteDateTimeUtc
        );
      })();
    }
    if (routeName !== prevRoute) {
      setPrevRoute(routeName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname]);

  return {};
};
