import { Card, LinearProgressBar } from '@dayinsure/components';
import { useMemo } from 'react';
import { PolicyState } from '../../../../api/v1';
import { getActivityDate } from '../../../../helpers';

type PolicyInfoDetailsProps = {
  testId?: string;
  details: {
    id?: string | null;
    startDate?: string | null;
    endDate?: string | null;
    cancelledDate?: string | null;
    state?: string | null;
    policyProgress?: number | null;
  };
};

export const PolicyInfoDetails = ({ testId, details }: PolicyInfoDetailsProps) => {
  const renderInfoBody = useMemo(() => {
    switch (details.state) {
      case PolicyState.ACTIVE:
        return (
          <>
            <span className="flex mt-6 mb-2 leading-[120%]">
              Active policy
              {details.id && ` (ID ${details.id})`}
            </span>
            <LinearProgressBar
              id="active-policy-progress-bar"
              progressPercentage={details.policyProgress || 0}
            />
          </>
        );
      case PolicyState.UPCOMING: {
        return (
          <>
            <span className="flex mt-6 mb-2 leading-[120%]">
              Upcoming policy
              {details.id && ` (ID ${details.id})`}
            </span>
            <LinearProgressBar
              id="active-policy-progress-bar"
              progressPercentage={details.policyProgress || 0}
            />
          </>
        );
      }
      case PolicyState.CANCELLED:
        return (
          <>
            <span className="flex mt-6 mb-2 leading-[120%]">
              Cancelled policy
              {details.id && ` (ID ${details.id})`}
            </span>
          </>
        );
      case PolicyState.EXPIRED:
        return (
          <>
            <span className="flex mt-6 mb-2 leading-[120%]">
              Previous policy
              {details.id && ` (ID ${details.id})`}
            </span>
          </>
        );
      default:
        return '';
    }
  }, [details]);
  return (
    <section>
      <Card
        key={details.id}
        paddingLevel="small"
        classNames="bg-content-background-prominent mb-6"
        testId={`${testId}_card_${details.id}`}
      >
        <h2 className="mb-4 font-raleway text-lg">Policy</h2>
        {renderInfoBody}
        <span className="flex mt-2 text-sm leading-[120%] text-main-content-2">
          {getActivityDate(details)}
        </span>
      </Card>
    </section>
  );
};
