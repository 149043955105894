import { MarketingPreferences } from '../../types';
import { MarketingConsentsRequestDto } from '../../api/v1';

export const toMarketingPreferencesDto = (
  preferences: MarketingPreferences
): MarketingConsentsRequestDto => {
  return {
    marketingConsents: preferences.map(pref => ({
      type: pref.id,
      isConsented: pref.checked,
      value: pref.name,
    })),
  };
};
