import { Proposer } from '../../../../types';
import { MarketingConsentDto } from '../../../../api/v1';

export const mergeMarketingPreferences = (
  formMarketingPreferences: Proposer['marketingPreferences'],
  personMarketingConsents: MarketingConsentDto[]
): Proposer['marketingPreferences'] => {
  return formMarketingPreferences.map(pref => {
    const presonPref = personMarketingConsents?.find(apiPref => pref.id === apiPref.type);

    if (presonPref) {
      return { ...pref, checked: !!presonPref.isConsented };
    }

    return pref;
  });
};
