import { MotorPolicyPaymentPlanDto } from '../../../api/v1';
import { isMonthlyPaymentPlan } from '../../utils';

export const isSamePrice = (paymentPlan?: MotorPolicyPaymentPlanDto): boolean => {
  const isMonthly = isMonthlyPaymentPlan(paymentPlan?.paymentFrequency?.code);
  const costDifference = isMonthly
    ? paymentPlan?.installmentDetails?.financeAmount?.amount
    : paymentPlan?.installmentDetails?.total?.amount;

  return typeof costDifference === 'number' && costDifference === 0;
};
