import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { ListboxOption } from '@dayinsure/components';
import { useAddressDetailQuery } from '../../../../hooks';
import { AddressSearch, AddressSearchProps } from '../AddressSearch';

type WithAddressDetailsProps = {
  addressDetailsInputName: string;
} & AddressSearchProps;

const withAddressDetails =
  (WrappedComponent: typeof AddressSearch) =>
  (componentProps: WithAddressDetailsProps) => {
    const { addressDetailsInputName } = componentProps;
    const [postKey, setPostKey] = useState<string | null>(null);
    const { setFieldValue } = useFormikContext();

    const {
      data: addressDetail,
      isFetching: addressDetailFetching,
      error: addressDetailError,
    } = useAddressDetailQuery(postKey || undefined);

    useEffect(() => {
      if (!addressDetailError && addressDetail && postKey) {
        setFieldValue(addressDetailsInputName, addressDetail);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressDetail]);

    const onAddressSelect = (address: ListboxOption) => {
      if (address.postKey) {
        setPostKey(address?.postKey);
      }
    };

    return (
      <WrappedComponent
        {...componentProps}
        isFetching={addressDetailFetching}
        onAddressSelect={onAddressSelect}
        addressDetailsInputName={addressDetailsInputName}
      />
    );
  };

export const SearchAddressDetails = withAddressDetails(AddressSearch);
